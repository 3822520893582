import React, { useEffect } from 'react';
import { Constant } from '../../../shared';
import { registerAction } from '../../../core/services/socket/socket';
import { connect } from 'react-redux';
import * as appStore from '../../../core/store/app';
import { emitMessageEvent } from '../services/utility/utility';

const StaffMessageSocket = (props) => {
  const { staffMessaging, setUnreadMessagesCount, app } = props;
  const newMessage = (data) => {
    if (data.type === Constant.SOCKET_EVENT_TYPES.MESSAGE) {
      setUnreadMessagesCount(app.unreadMessagesCount + 1);
    }
    emitMessageEvent(data);
  };

  useEffect(
    function () {
      if (!Constant.POLL_CONFIG.USE_POLLING) {
        registerAction({
          event: 'new_staff_message',
          action: newMessage,
        });
      }
    },
    [staffMessaging, setUnreadMessagesCount, app.unreadMessagesCount]
  );

  return null;
};

const mapStateToProps = (state) => ({
  staffMessaging: state.staffMessaging,
  app: state.app,
});

const mapDispatchToProps = (dispatch) => {
  return {
    setUnreadMessagesCount: (count) => dispatch(appStore.setUnreadMessagesCount(count)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(StaffMessageSocket);
