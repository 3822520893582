import React, { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { getContentRecommendations } from '../../services/personality-quiz.service';

import { Constant } from '../../../../../shared/services';
import { PENDO_TRACK_EVENTS_ENUM, trackPendoEvent } from '../../../../../core';
import { refineTags } from '../../../../shared/services/utility/utility';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const useRecommendationsHook = (selectedLocale, selectedCluster, classes) => {
  const history = useHistory();
  const [recommendation, setRecommendation] = useState([]);
  const [recommendationType, setRecommendationType] = useState();

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await getContentRecommendations(selectedCluster);
        setRecommendation(response);
        if (response.length > 0) {
          setRecommendationType(response[0].type);
        }
      } catch (err) {
        console.error('Error fetching recommended programs:', err);
      }
    };

    fetchPrograms();
  }, [selectedLocale, selectedCluster]);

  useEffect(() => {
    if (recommendation && recommendation.length > 0) {
      trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.RECOMMENDATION_PROVIDED, {
        careerCluster: selectedCluster,
        type: recommendationType,
      });
    } else {
      trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.RECOMMENDATION_NOT_FOUND, {
        careerCluster: selectedCluster,
      });
    }
  }, [recommendation, selectedCluster, recommendationType]);

  const getTags = (tags) => {
    const elements = [];

    tags.forEach((item) => {
      if (item.name.includes(Constant.TAGS_KEYS.DURATION)) {
        if (elements.length > 0) {
          elements.push(' | ');
        }
        elements.push(refineTags(item.name));
      }
      if (
        item.name.includes(Constant.TAGS_KEYS.CERTIFICATE) &&
        !item.name.includes(Constant.TAGS_KEYS.CERTIFICATE_UNAVAILABLE)
      ) {
        if (elements.length > 0) {
          elements.push(' | ');
        }
        elements.push(<WorkspacePremiumIcon className={classes.ribbonIcon} />);
        elements.push(refineTags(item.name));
      }
    });
    return elements;
  };

  const handleNext = useCallback(
    (selectedOption, description) => {
      history.push(`/personality-quiz/${selectedOption}`, { description });
    },
    [history]
  );

  const ROUTE_CONFIG = {
    Program: {
      url: (id) => `/programs/${id}/details`,
      defaultUrl: '/programs',
    },
    Service: {
      url: (categoryId) => `/explore#service-category-${categoryId}`,
      defaultUrl: '/explore',
    },
  };

  const getRouteUrl = useCallback(
    (type, idOrCategoryId) => {
      const routeConfig = ROUTE_CONFIG[type];
      if (routeConfig) {
        return routeConfig.url(idOrCategoryId);
      }
      return null;
    },
    [ROUTE_CONFIG]
  );

  const getDefaultRouteUrl = useCallback(
    (type) => {
      const routeConfig = ROUTE_CONFIG[type];
      return routeConfig ? routeConfig.defaultUrl : null;
    },
    [ROUTE_CONFIG]
  );

  const navigateToAllContent = useCallback(() => {
    history.push(getDefaultRouteUrl(recommendationType));
  }, [history, recommendationType, getDefaultRouteUrl]);

  const handleLearnMore = useCallback(
    (program) => {
      trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.RECOMMENDATION_CONTENT_LAUNCHED, {
        id: program?.id,
      });
      history.push(
        getRouteUrl(
          program?.type,
          program?.type === Constant.RECOMMENDATION_TYPES.PROGRAM ? program?.id : program.category?.id
        )
      );
    },
    [history, getRouteUrl]
  );

  const handleExploreClick = useCallback(() => {
    history.push('/explore');
  }, [history]);

  const handleProgramClick = useCallback(() => {
    history.push('/programs');
  }, [history]);

  return {
    recommendation,
    recommendationType,
    getTags,
    handleNext,
    navigateToAllContent,
    getRouteUrl,
    getDefaultRouteUrl,
    handleLearnMore,
    handleExploreClick,
    handleProgramClick,
  };
};

export default useRecommendationsHook;
