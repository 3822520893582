import { Avatar, Box, Button, Card, CardContent, Grid, MenuItem, TextField, Snackbar } from '@material-ui/core';
import { Typography, Chip } from '@orijinworks/frontend-commons';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core/styles';
import { PendingIcon, CheckCircleIcon } from '@orijinworks/frontend-commons';
import ErrorIcon from '@material-ui/icons/Error';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import BlockIcon from '@mui/icons-material/Block';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { ContactRequestConstants } from '../../services';
import StatusModal from '../status-modal/status-modal';
import VoiceAuthenticationBanner from '../voice-authentication-banner/voice-authentication-banner';
import styles from './contacts-requests.style';
import useContactsRequestsHook from './useContactsRequestsHook';
import ArchiveUnArchiveContactModal from '../archieve-user-contact-modal/archive-unarchive-contact-modal';
import ArchiveUnarchiveIcon from '../archive-unarchive-icon/archive-unarchive-icon';
const ContactsRequests = (props) => {
  const { classes, handletabActive, TAB_VALUES, isVoiceIDRegistrationRequired, profileStatus, isContactRequestAllowed } =
    props;
  const {
    contact,
    statusModalOpen,
    contactRequests,
    contactRequestLimitReached,
    handleStatusModalClose,
    handleStatusModalOpen,
    handleContactChange,
    handleSubmitContact,
    reqStatus,
    openModal,
    closeModal,
    archiveUnArchiveContactModalRef,
    handleSnackbarToggle,
    isSnackbarOpen,
    snackbarMessage,
    snackbarType,
    contactName,
    archiveContacts,
    unArchiveContacts,
    formatPhoneNumber,
  } = useContactsRequestsHook(props);
  const intl = useIntl();

  const renderVoiceAuthenticationBanner = () => {
    return (
      <VoiceAuthenticationBanner
        showCreateButton={true}
        handletabActive={handletabActive}
        TAB_VALUES={TAB_VALUES}
        profileStatus={profileStatus}
      />
    );
  };

  const getStatusIcon = (status) => {
    switch (status) {
      case ContactRequestConstants.CONTACT_REQUEST_STATUSES.APPROVED:
        return (
          <CheckCircleIcon
            className={[classes.icon, classes[`${ContactRequestConstants.CONTACT_REQUEST_STATUSES.APPROVED}Icon`]].join(' ')}
          />
        );
      case ContactRequestConstants.CONTACT_REQUEST_STATUSES.BLOCKED:
        return (
          <BlockIcon
            className={[classes.icon, classes[`${ContactRequestConstants.CONTACT_REQUEST_STATUSES.BLOCKED}Icon`]].join(' ')}
          />
        );
      case ContactRequestConstants.CONTACT_REQUEST_STATUSES.PENDING:
        return (
          <PendingIcon
            className={[classes.icon, classes[`${ContactRequestConstants.CONTACT_REQUEST_STATUSES.PENDING}Icon`]].join(' ')}
          />
        );
      case ContactRequestConstants.CONTACT_REQUEST_STATUSES.REJECTED:
        return (
          <RemoveCircleIcon
            className={[classes.icon, classes[`${ContactRequestConstants.CONTACT_REQUEST_STATUSES.REJECTED}Icon`]].join(' ')}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        {isVoiceIDRegistrationRequired && (
          <Grid item sm={12}>
            <Box ml={4}>{renderVoiceAuthenticationBanner()}</Box>
          </Grid>
        )}
        {isContactRequestAllowed && (
          <Grid item sm={12}>
            <Card className={classes.parentCard} elevation={0}>
              <Grid item sm={12}>
                <CardContent>
                  <Typography variant="h2" className={classes.heading}>
                    <FormattedMessage id="addAContact" />
                  </Typography>
                  {!contactRequestLimitReached ? (
                    <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>
                      <FormattedMessage id="addAContactDesc" />
                    </Typography>
                  ) : (
                    <Typography variant="body1" className={classes.contactRequestReached}>
                      <ErrorIcon className={classes.ErrorIcon} />
                      <FormattedMessage id="contactRequestLimitReached" />
                    </Typography>
                  )}
                </CardContent>
              </Grid>
              <Grid item sm={12}>
                <CardContent className={classes.formContainer}>
                  <Box className={classes.formWrapper}>
                    <Box className={classes.fieldWrapper}>
                      <TextField
                        id="firstName"
                        required
                        error={contact.isSubmitted && contact.errors.hasOwnProperty('firstName')}
                        helperText={contact.isSubmitted ? contact.errors.firstName : ''}
                        onChange={handleContactChange}
                        name="firstName"
                        value={contact.firstName}
                        className={classes.formField}
                        autoFocus
                        label={<FormattedMessage id="firstName" />}
                        variant="outlined"
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <TextField
                        id="lastName"
                        required
                        error={contact.isSubmitted && contact.errors.hasOwnProperty('lastName')}
                        helperText={contact.isSubmitted ? contact.errors.lastName : ''}
                        onChange={handleContactChange}
                        name="lastName"
                        value={contact.lastName}
                        className={classes.formField}
                        label={<FormattedMessage id="lastName" />}
                        variant="outlined"
                      />
                    </Box>
                    <Box className={classes.fieldWrapper} position="relative">
                      <Box className={classes.phoneSideElement}>
                        <img src="/assets/img/usa.jpg" alt="Enter a U.S.A. phone number" className={classes.USAFlag} />1
                      </Box>
                      <TextField
                        id="phoneNumber"
                        required
                        error={contact.isSubmitted && contact.errors.hasOwnProperty('phoneNumber')}
                        helperText={contact.isSubmitted ? contact.errors.phoneNumber : ''}
                        onChange={handleContactChange}
                        value={contact.phoneNumber}
                        name="phoneNumber"
                        placeholder="XXXXXXXXXX"
                        classes={{ root: classes.phone }}
                        InputLabelProps={{ shrink: true }}
                        className={[classes.formField].join(' ')}
                        label={<FormattedMessage id="phoneNumber" />}
                        variant="outlined"
                      />
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <TextField
                        error={contact.isSubmitted && contact.errors.hasOwnProperty('relationshipType')}
                        helperText={contact.isSubmitted ? contact.errors.relationshipType : ''}
                        className={classes.formField}
                        required
                        select
                        onChange={handleContactChange}
                        name="relationshipType"
                        variant="outlined"
                        label={<FormattedMessage id="relationship" />}
                        value={contact.relationshipType}
                      >
                        <MenuItem value={'FRIEND'}>
                          <FormattedMessage id="friend" />
                        </MenuItem>
                        <MenuItem value={'FAMILY'}>
                          <FormattedMessage id="family" />
                        </MenuItem>
                        <MenuItem value={'GENERAL_CONTACT'}>
                          <FormattedMessage id="general_contact" />
                        </MenuItem>
                      </TextField>
                    </Box>
                    <Box className={classes.fieldWrapper}>
                      <TextField
                        id="prefferredLanguage"
                        className={classes.formField}
                        select
                        onChange={handleContactChange}
                        name="preferredLanguage"
                        variant="outlined"
                        label={<FormattedMessage id="preferredLanguage" />}
                        value={contact.preferredLanguage}
                      >
                        <MenuItem value={'en-US'}>
                          <FormattedMessage id="english" />
                        </MenuItem>
                        <MenuItem value={'es-US'}>
                          <FormattedMessage id="spanish" />
                        </MenuItem>
                      </TextField>
                    </Box>
                  </Box>
                  <Box className={classes.submitBtn}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={handleSubmitContact}
                      className={classes.btnRadius}
                      disabled={contactRequestLimitReached}
                      aria-label="Submit Request"
                    >
                      <FormattedMessage id="submitRequest" />
                    </Button>
                  </Box>
                </CardContent>
              </Grid>
            </Card>
          </Grid>
        )}

        <Grid item sm={12}>
          <Box>
            <Card className={classes.parentCard} elevation={0}>
              <CardContent>
                <Typography variant="h2" className={classes.heading}>
                  <FormattedMessage id={'contact-history'} />
                </Typography>
              </CardContent>

              <CardContent>
                {contactRequests?.map((contactRequest, index) => (
                  <>
                    <Card key={contactRequest.phoneNumber} className={classes.contactRow}>
                      <CardContent>
                        <Grid container spacing={2} alignItems="center" key={index} className={classes.submitedContacts}>
                          <Grid item>
                            <Avatar className={classes.avatar}>{contactRequest?.firstName?.charAt(0)}</Avatar>
                          </Grid>

                          <Grid item xs={4}>
                            <Typography variant="bodyDecorFs20" className={classes.name}>
                              {contactRequest?.firstName + ' ' + contactRequest?.lastName}
                            </Typography>
                            <Box height={4} />
                            <Typography variant="overlineLarge" className={classes.contactRelationship}>
                              <FormattedMessage id={contactRequest.relationshipType.toLowerCase()}>
                                {(text) => text.toUpperCase()}
                              </FormattedMessage>
                            </Typography>
                            <Typography
                              variant="body1"
                              className={classes.contactNumber}
                              aria-label={formatPhoneNumber(contactRequest.phoneNumber)}
                            >
                              {formatPhoneNumber(contactRequest.phoneNumber)}
                            </Typography>
                          </Grid>
                          <Grid item xs={2} sm={2}></Grid>

                          <Grid item>
                            <Chip
                              key={index}
                              label={
                                <FormattedMessage id={contactRequest.status.toLowerCase()}>
                                  {(text) => text.toUpperCase()}
                                </FormattedMessage>
                              }
                              variant="outlined"
                              size="medium"
                              icon={getStatusIcon(contactRequest.status.toLowerCase())}
                              classes={{
                                root: classes[contactRequest.status.toLowerCase()],
                                outlined: classes[`${contactRequest.status.toLowerCase()}Outlined`],
                              }}
                            />

                            <ArchiveUnarchiveIcon
                              contactRequest={contactRequest}
                              unArchiveContacts={unArchiveContacts}
                              openModal={openModal}
                            />
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  </>
                ))}
              </CardContent>
            </Card>
          </Box>
        </Grid>
      </Grid>
      <ArchiveUnArchiveContactModal
        ref={archiveUnArchiveContactModalRef}
        isOpen={false}
        onClose={closeModal}
        contactName={contactName}
        onContinue={archiveContacts}
      />

      <Snackbar open={isSnackbarOpen} autoHideDuration={6000} onClose={handleSnackbarToggle}>
        <MuiAlert severity={snackbarType} elevation={6} variant="filled">
          {snackbarMessage}
        </MuiAlert>
      </Snackbar>
      <StatusModal
        status={reqStatus.toLowerCase()}
        description={contact.errors.error}
        handleStatusModalOpen={handleStatusModalOpen}
        handleStatusModalClose={handleStatusModalClose}
        isStatusModalOpen={statusModalOpen}
      />
    </>
  );
};

export default withStyles(styles)(ContactsRequests);
