import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { CareersService } from '../../../../../content/careers/services/careers.service';

const useCareerInMindStepHook = (selectedLocale, selectedCareerCluster) => {
  const history = useHistory();
  const [careerClusterData, setCareerClusterData] = useState([]);
  const [careerClusterMap, setCareerClusterMap] = useState({});
  useEffect(() => {
    const fetchCareerClusters = async () => {
      try {
        let service = new CareersService();
        const response = await service.getCareerClusters();
        setCareerClusterData(prepareCareerClusterData(response?.payload?.clusters));
      } catch (err) {
        console.error('Error fetching career cluster:', err);
      }
    };

    fetchCareerClusters();
  }, [selectedLocale]);

  const handleThisIsMe = useCallback(() => {
    if (!selectedCareerCluster) return;
    const clusterCode = careerClusterMap[selectedCareerCluster];
    history.push(`/personality-quiz/recommendations`, { selectedCluster: clusterCode });
  }, [history, selectedCareerCluster, careerClusterMap]);

  const prepareCareerClusterData = (data) => {
    const titleMap = {};
    data.forEach((item) => {
      titleMap[item.title] = item.code;
    });
    setCareerClusterMap(titleMap);
    return data.map((item) => item.title);
  };

  const handleCareerInMindClick = useCallback(() => {
    history.push('/careers');
  }, [history]);

  return {
    careerClusterData,
    handleThisIsMe,
    handleCareerInMindClick,
  };
};

export default useCareerInMindStepHook;
