import React, { forwardRef } from 'react';
import { Modal, Avatar, ErrorIcon, Typography } from '@orijinworks/frontend-commons';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import styles from './vrs-error-modal.style';

const VRSErrorModal = forwardRef(({ isOpen, closeModal, classes }, ref) => {
  return (
    <Modal
      ref={ref}
      Icon={
        <Avatar
          renderState={'icon'}
          avatarProps={{
            Icon: <ErrorIcon />,
            backgroundColor: '#E32D4E',
          }}
          iconSize={40}
          firstName=""
          lastName=""
        />
      }
      open={isOpen}
      onClose={closeModal}
      heading=""
      styles={{
        mainWrapperStyles: {
          height: '289px',
          width: '427px',
          zIndex: 9999,
        },
      }}
      showFooter={false}
    >
      <Typography variant="h5" className={classes.headingText} id="modal-heading">
        <FormattedMessage id="vrsRelayServiceUnavailable" />
      </Typography>
      <Typography variant="body1" className={classes.bodyText} id="modal-description">
        <FormattedMessage id="vrsRelayServiceUnavailableMsg" />
      </Typography>
    </Modal>
  );
});

export default withStyles(styles)(VRSErrorModal);
