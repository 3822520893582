export default (theme) => ({
  content: {
    width: '100%',
  },
  title: {
    ...theme.typography.t20,
    textTransform: 'uppercase',
    fontWeight: 700,
    marginBottom: 30,
  },
  disabled: {
    color: 'rgba(51,51,51,1) !important',
    fontWeight: theme.typography.regular.fontWeight,
    fontSize: theme.typography.t16.fontSize,
  },
  disabledInput: {
    background: 'rgba(0, 0, 0, 0.13)',
  },
  gutterTop: {
    marginTop: 30,
  },
  helperText: {
    fontSize: '1rem',
    marginLeft: 0,
  },
  infoIcon: {
    color: theme.palette.aqua.extraDeep,
    marginLeft: '10px !important',
  },
  popoverPaper: {
    width: 275,
    padding: 10,
    backgroundColor: theme.palette.colors.inputBackground,
    color: theme.palette.text.sub,
  },
  scaleContainer: {
    display: 'flex',
    marginTop: 10,
  },
  scaleOption: {
    borderColor: theme.palette.colors.borderGrey.dark,
    border: '2px solid',
    ...theme.typography.t20,
    fontWeight: 700,
    minWidth: 45,
    minHeight: 45,
    width: 45,
    height: 45,
    backgroundColor: theme.palette.colors.inputBackground,
  },
  activeScaleOption: {
    borderColor: theme.palette.primary.main,
    color: theme.palette.primary.main,
    backgroundColor: `${theme.palette.primary.main}40`,
  },
  questionTitle: {
    fontFamily: 'Inter',
    fontWeight: theme.typography.bold.fontWeight,
    fontSize: theme.typography.t16.fontSize,
    lineHeight: '24px',
    textTransform: 'none',
    color: theme.palette.navy.main,
  },
  questionTitleDisabled: {
    color: 'rgba(199,199,199,1)',
    fontWeight: theme.typography.bold.fontWeight,
    fontSize: theme.typography.t16.fontSize,
  },
});
