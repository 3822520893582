import moment from 'moment';
import { Http } from '../../../../core';

class TranscriptConstants {
  /**
   * @name VERSIONS
   * @description The different versions of the transcript
   * @returns {Object}
   */
  static get VERSIONS() {
    return {
      V1: 'V1',
      V2: 'V2',
    };
  }
}

/**
 * @name convertToHoursIfGreaterThanOne
 * @desc convert minutes to hours if greater than 60
 * @param {Number} minutes
 * @param {String} hoursLabel
 * @param {String} minsLabel
 * @returns {String}
 */
const convertToHoursIfGreaterThanOne = (minutes, hoursLabel = 'Hours', minsLabel = 'Mins') => {
  let value = '';
  if (minutes > 0) {
    value = `${minutes} ${minsLabel}`;
  }
  if (minutes > 60) {
    let hours = parseFloat(minutes / 60);
    if (minutes % 60 === 0) {
      hours = parseInt(hours);
    } else {
      hours = hours.toFixed(1);
    }
    value = `${hours} ${hoursLabel}`;
  }
  return value;
};

/**
 * @name formatDate
 * @desc format date to MM/DD/YYYY
 * @param {String} date
 * @returns {String}
 */
const formatDate = (date) => {
  let value = '';
  if (date) {
    value = moment(date).format('MM/DD/YYYY');
  }
  return value;
};

/**
 * @name appendPercentage
 * @desc append % to number
 * @param {Number} number
 * @returns {String}
 */
const appendPercentage = (number) => {
  let value = '';
  if (number || number === 0) {
    value = `${number}%`;
  }
  return value;
};

/**
 * @name doesTranscriptHaveContents
 * @desc check if transcript has contents
 * @param {Array} data
 * @returns {Boolean}
 */
const doesTranscriptHaveContents = (data) => {
  let flag = false;
  if (data?.length > 0 && data?.filter((item) => item.programs?.length > 0).length > 0) {
    flag = true;
  }
  return flag;
};

/**
 * @name getTranscriptData
 * @desc retrieve transcript data
 * @returns {Promise}
 */
const getTranscriptData = () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/achievement/v2/transcript`)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export {
  TranscriptConstants,
  convertToHoursIfGreaterThanOne,
  formatDate,
  appendPercentage,
  doesTranscriptHaveContents,
  getTranscriptData,
};
