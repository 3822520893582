export default (theme) => ({
  root: {
    paddingTop: 24,
    minWidth: 665,
    width: '100%',
  },
  container: {
    borderRadius: '16px',
    padding: 32,
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    position: 'relative',
    border: `1px solid ${theme.palette.greyScale[60]}`,
  },
  title: {
    display: 'flex',
    alignItems: 'center',
    gap: 12,
    color: theme.palette.navy.main,
  },
  description: {
    color: theme.palette.greyScale[100],
    maxWidth: '80%',
  },
  button: {
    height: '40px',
    whiteSpace: 'nowrap',
    borderRadius: '8px',
    textTransform: 'none !important',
  },
  contentWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    alignItems: 'flex-end',
    gap: '32px',
  },
});
