import { Grid } from '@material-ui/core';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Constant } from '../../../../shared/services';
import styles from './program-filter.style';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../core/services/unleash/unleash';

const ProgramFilters = (props) => {
  const { classes, unleashProps } = props;
  const intl = useIntl();
  return (
    <Grid item sm={12} md={12}>
      <List className={classes.filterWrapper}>
        <ListItem
          button
          data-cy-name="all-programs-filter"
          id="all-programs-filter"
          onClick={() => props.handleFilterChange('all')}
          className={[classes.filterItem, props.selectedFilter === 'all' ? classes.activeFilter : null].join(' ')}
          tracking-type={Constant.TRACKING_TYPES.PROGRAM}
          tracking-id="view-all-programs"
          aria-label={intl.formatMessage({ id: 'allPrograms' })}
        >
          <FormattedMessage id="allPrograms" />
        </ListItem>
        <ListItem
          data-cy-name="my-programs-filter"
          id="my-programs-filter"
          onClick={() => props.handleFilterChange('enrolled')}
          button
          className={[classes.filterItem, props.selectedFilter === 'enrolled' ? classes.activeFilter : null].join(' ')}
          tracking-type={Constant.TRACKING_TYPES.PROGRAM}
          tracking-id="view-enrolled-programs"
          aria-label={intl.formatMessage({ id: 'myPrograms' })}
        >
          <FormattedMessage id="myPrograms" />
        </ListItem>
        {unleashProps.isFlagEnabled(UnleashService.FLAGS.PROGRAM_CATALOG_COMPLETED_TAB) && (
          <ListItem
            data-cy-name="completed-programs-filter"
            id="completed-programs-filter"
            onClick={() => props.handleFilterChange('completed')}
            button
            className={[classes.filterItem, props.selectedFilter === 'completed' ? classes.activeFilter : null].join(' ')}
            tracking-type={Constant.TRACKING_TYPES.PROGRAM}
            tracking-id="view-completed-programs"
            aria-label={intl.formatMessage({ id: 'completedPrograms' })}
          >
            <FormattedMessage id="completedPrograms" />
          </ListItem>
        )}
      </List>
    </Grid>
  );
};

export default withUnleash(withStyles(styles)(ProgramFilters), UnleashService.FLAGS.PROGRAM_CATALOG_COMPLETED_TAB);
