import { useState, useRef, useEffect } from 'react';
import { ContactRequestConstants, initiateVRSCall } from '../../services';
import { UserService } from '../../../../shared';
const useVRSCallingModalHook = (closeModal, voiceCall) => {
  const [statusModalOpen, setStatusModalOpen] = useState(false);
  const vrsErrorModalRef = useRef(null);
  const VrsCallInProgressModalRef = useRef(null);
  const userService = new UserService();

  useEffect(() => {
    if (voiceCall.meetingInfo) {
      toggleModalForCallInProgress(voiceCall.meetingInfo);
    }
  }, [voiceCall?.meetingInfo]);

  const toggleVrsErrorModal = () => {
    vrsErrorModalRef.current.toggleModal();
  };
  const toggleVrsCallInProgressModal = () => {
    VrsCallInProgressModalRef.current.toggleModal();
  };

  const handleStatusModalOpen = () => {
    setStatusModalOpen(true);
  };
  const handleStatusModalClose = () => {
    setStatusModalOpen(false);
  };

  const handleOnClickVRSCall = async (contact) => {
    try {
      console.log('serial', userService.extractSerial());
      const response = await initiateVRSCall(contact.phoneNumber, userService.extractSerial());
      console.log('response', response);
      if (response.data.statusCode === 'SUCCESS') {
        const intentUrl = response.data.intentUrl;
        if (localStorage.getItem('meetingId') && localStorage.getItem('intentUrl')) {
          localStorage.removeItem('meetingId');
          localStorage.removeItem('intentUrl');
        }

        localStorage.setItem('meetingId', response.data.meetingId);
        localStorage.setItem('intentUrl', intentUrl);
        closeModal();
        window.location.href = intentUrl;
      } else if (response.data.statusCode === 'P3_APP_NOT_INSTALLED') {
        console.log('P3_APP_NOT_INSTALLED');
        toggleVrsErrorModal();
      } else {
        console.log('VRS error');
      }
    } catch (error) {
      if (
        error.response.status === 400 &&
        error.response.data.errorMessage[0] === 'There is already an active call in progress'
      ) {
        closeModal();
        toggleVrsCallInProgressModal();
      } else console.error('Error initiating VRS call', error);
    }
  };

  const toggleModalForCallInProgress = (meetingInfo) => {
    try {
      if (ContactRequestConstants.CALL_TYPE.VRS === meetingInfo.callType) {
        toggleVrsCallInProgressModal();
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    statusModalOpen,
    toggleModalForCallInProgress,
    handleStatusModalOpen,
    handleStatusModalClose,
    setStatusModalOpen,
    handleOnClickVRSCall,
    vrsErrorModalRef,
    toggleVrsErrorModal,
    VrsCallInProgressModalRef,
    toggleVrsCallInProgressModal,
  };
};
export default useVRSCallingModalHook;
