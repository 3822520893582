import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(2),
  },
  description: {
    color: theme.palette.greyScale[110],
    width: '100%',
    marginBottom: '20px !important',
  },
  button: {
    borderRadius: 100,
    textTransform: 'none',
    border: `1px solid ${theme.palette.accentTeal.main}`,
    backgroundColor: theme.palette.accentTeal.main,
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: theme.palette.accentTeal.dark,
      border: `2px solid ${theme.palette.accentTeal.dark}`,
    },
  },
}));
