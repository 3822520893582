import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { PENDO_TRACK_EVENTS_ENUM, trackPendoEvent } from '../../../../../core/';
import { Constant, Utility } from '../../../../../shared/services';

const usePersonalityQuizTileHook = ({ categoryId, features, unleashProps }) => {
  const history = useHistory();
  const _utility = new Utility();

  const isFeatureEnabled = () => {
    return _utility.checkStatusExists(features, Constant.FEATURES_ENUM.PERSONALITY_QUIZ);
  };

  const isCategoryEducation = () => {
    return categoryId === Constant.SERVICE_CATEGORIES_ENUM.EDUCATION;
  };

  const showTile = () => {
    const flag = isFeatureEnabled() && isCategoryEducation() && unleashProps.isFlagEnabled();
    if (flag) {
      trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.RECOMMENDATION_QUIZ_DISPLAYED, {
        source: 'explore',
      });
    }
    return flag;
  };

  const launchPersonalityQuiz = () => {
    trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.RECOMMENDATION_QUIZ_STARTED, {
      source: 'explore',
    });
    history.push('/personality-quiz');
  };

  return {
    showTile,
    launchPersonalityQuiz,
  };
};

export default usePersonalityQuizTileHook;
