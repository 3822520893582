import React from 'react';
import { Box, Typography, withStyles } from '@material-ui/core';
import styles from './voice-id-submitted.style';
import { Button } from '@orijinworks/frontend-commons';
import { FormattedMessage } from 'react-intl';
const VoiceIdSubmitted = ({ classes, TAB_VALUES, handletabActive }) => {
  return (
    <>
      <Box className={classes.rootContainer}>
        <Box className={classes.container}>
          <Box className={classes.imageSection}>
            <img src="assets/img/success_illustration.png" alt="Voice ID Submitted" loading="eager" />
          </Box>

          <Box className={classes.textSection}>
            <Typography variant="h2" className={classes.heading}>
              <FormattedMessage id="voiceIdSubmitted.heading" />
            </Typography>
            <Typography variant="body2" className={classes.description}>
              <FormattedMessage id="voiceIdSubmitted.description" />
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default withStyles(styles)(VoiceIdSubmitted);
