import React from 'react';
import { withStyles } from '@material-ui/styles';
import styles from './call-history-logs.style';
import { Box } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import { FormattedMessage } from 'react-intl';
import CallMadeIcon from '@material-ui/icons/CallMade';
import PhoneMissedIcon from '@material-ui/icons/PhoneMissed';
import TimerIcon from '@mui/icons-material/Timer';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
import useCallHistoryLogsHook from './useCallHistoryLogsHook';
const CallHistoryLogs = ({ classes }) => {
  const { callHistoryLogs, formatDate, formatTime, formatPhoneNumber, userTimeZone } = useCallHistoryLogsHook();
  const callTypeMapper = {
    COMPLETED: {
      icon: <CallMadeIcon className={classes.callCompletedIcon} />,
      label: 'Outgoing Call',
      className: classes.callCompletedIcon,
    },
    MISSED: {
      icon: <PhoneMissedIcon className={classes.callMissedIcon} />,
      label: 'Missed Call',
      className: classes.callMissedIcon,
    },
    TERMINATED: {
      icon: <NotInterestedIcon className={classes.callTerminatedIcon} />,
      label: 'Call Terminated',
      className: classes.callTerminatedIcon,
    },
    CANCELLED: {
      icon: <PhoneMissedIcon className={classes.callMissedIcon} />,
      label: 'Missed Call',
      className: classes.callMissedIcon,
    },
    DEFAULT: {
      icon: <PhoneMissedIcon className={classes.callMissedIcon} />,
      label: 'Unknown',
      className: classes.callMissedIcon,
    },
  };

  const formatTimeToMinutes = (timeString) => {
    const [minutes, seconds] = timeString
      .split(/[ms ]+/)
      .filter(Boolean)
      .map(Number);
    const totalMinutes = Math.ceil(minutes + seconds / 60);
    return totalMinutes === 1 ? `${totalMinutes} min` : `${totalMinutes} mins`;
  };

  return (
    <Box className={classes.root}>
      <Typography variant="overlineLarge" data-test-id="call-date">
        {formatDate()}
      </Typography>
      {callHistoryLogs.length === 0 ? (
        <Typography id="no-calls-display" className={classes.noCalls} variant="body1" data-test-id="no-calls">
          <FormattedMessage id="noCallsDisplay" />
        </Typography>
      ) : (
        callHistoryLogs?.map((log, index) => {
          const callType = callTypeMapper[log.callState] || callTypeMapper.DEFAULT;
          return (
            <Box key={index} className={classes.callLogItem} data-test-id="call-log-item">
              <Box paddingTop="4px" className={classes.callIcon}>
                {callType.icon}
              </Box>
              <Box marginLeft="32px" className={classes.callInfo}>
                <Typography variant="bodyDecorFs20" className={classes.callName} data-test-id="call-name">
                  {log.contactName}
                </Typography>
                <Typography variant="body1" className={classes.contact}>
                  <FormattedMessage id="contact" />: {formatPhoneNumber(log.contactPhoneNumber)}
                </Typography>
              </Box>
              <Box display="flex" justifyContent="space-between">
                <Typography marginLeft="32px" className={classes.callDetails} data-test-id="call-details">
                  <Typography className={`${callType.className} ${classes.callTypeLabel}`}>{callType.label}:</Typography>
                  {formatTime(log.joinTimeUtc, userTimeZone)}
                </Typography>
                {log.lengthOfCall && (
                  <Box className={classes.callDuration} data-test-id="call-duration">
                    <TimerIcon fontSize="small" className={classes.timerIcon} />
                    <Typography className={classes.callLogDuration}>{formatTimeToMinutes(log.lengthOfCall)}</Typography>
                  </Box>
                )}
              </Box>
            </Box>
          );
        })
      )}
    </Box>
  );
};

export default withStyles(styles)(CallHistoryLogs);
