import React from 'react';
import PropTypes from 'prop-types';
import { withKeycloak } from '@react-keycloak/web';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import * as appStore from '../../../core/store/app';
import * as earnStore from '../../../core/store/earn-tokens';
import * as interestProfilerStore from '../../../core/store/interest-profiler';
import * as pdqAssessmentStore from '../../../core/store/pdq-assessment';
import { Constant, Error, UserService, Utility, IdleTimeout, BackToTopButton, SessionExpireView } from '../../../shared';
import { Layout, Routes, setUserTokenBalance, setUserTransaction, currentUser, setLocale, store } from '../../';
import { Closed } from '../../../content/play/components';
import { Logging } from '../../../shared/services/logging/Logging';
import moment from 'moment';
import { Tracker } from '../../services';
import { getCookie, setCookie } from '../../../shared/services/utility/cookie';
import { registerAction } from '../../services/socket/socket';
import { LearnosityAssessment, MessagingService, Onboarding } from '../../../content';
import { InterestProfilerService } from '../../../content/interest-profiler/services/interest-profiler.service';
import { Header } from '../../components';
import { PDQAssessmentService } from '../../../content/pdq-assessment/services/pdq-assessment.service';
import {
  generateAuthSessionCorrelation,
  handleAuthentication,
  handleLogout,
  isAuthenticated,
} from '../../../v2/core/auth/utils';
import { withAuth0 } from '@auth0/auth0-react';
import { isPublicRoute } from '../../../v2/shared/services/utility/utility';
import StaffMessageSocket from '../../../v2/shared/hooks/useMessageHook';
import { logoutFromPartnerSystems } from '../../../v2/shared/services/partner-logout/partner-logout.service';
import { recordRUMEvent, RUM_EVENTS_ENUM } from '../../../v2/core/services/rum/rum.service';
import { AUTH_CONSTANTS } from '../../../v2/core/auth/constants/auth-constants';
import ValidateSession from '../../../v2/core/auth/validate-session/validate-session';

export class AuthContainer extends React.Component {
  tracker = new Tracker();
  routes = new Routes();
  loggingService = new Logging();
  utilityService = new Utility();
  earnTokenAjaxCall = false;
  userService = new UserService();
  interestProfilerService = new InterestProfilerService();
  pdqService = new PDQAssessmentService();
  userTokenPollIntervalRef = null;
  pollCntr = 0;
  unreadPollCntr = 0;
  isFnFUnreadCountCalling = false;

  constructor(props) {
    super(props);
    this.state = {
      authorize: false,
      authCheckPerformed: false,
      showOnboarding: false,
      hasAuthError: false,
      performingAuthCheck: false,
    };
  }

  onNewAnnouncementCallback = (data) => {
    this.props.setUnreadAnnouncementCount(this.props.announcementCount + 1);
  };

  onNewAnnouncementListener = () => {
    const announcementElement = document.querySelector(`#${MessagingService.ANNOUNCEMENT_ELEMENT}`);
    if (announcementElement) {
      announcementElement.addEventListener(MessagingService.NEW_ANNOUNCEMENT, this.onNewAnnouncementCallback, false);
    }
  };

  hideOnboarding = () => {
    this.setState({ showOnboarding: false });
  };

  /**
   * @name callAppLevelAPIS
   * @desc A wrapper method to call application level APIs.
   * @return {void}
   */
  callAppLevelAPIS = (username, authTime) => {
    this.getUserToken();
    this.getFeatures(true);
    this.getEarnTokenRules();
    this.getInterestProfilerAnswers();
    if (Constant.POLL_CONFIG.USE_ANNOUNCEMENT_POLLING) {
      this.pollUnreadMessageAPI();
    } else {
      this.getUnreadMessagesCount();
      this.onNewAnnouncementListener();
    }
    this.userService.getUserTimeMeta().then(async (userLocationTime) => {
      await this.loggingService.logDeviceInfo({
        userName: username,
        eventType: 'login',
        status: 'successful',
        userLocalTime: moment().format(),
        userLocationTime: userLocationTime.timeZone,
        kcAuthTime: moment.unix(authTime).utc().format(),
      });
      this.queryUser();
    });

    this.startAPIPolling(Constant.POLL_CONFIG.APPLICATION_WIDE_POLL_INTERVAL);
  };

  /**
   * @name pollUnreadMessageAPI
   * @desc A wrapper method that is polling unread messages on interval as passed in param.
   * @return {void}
   */
  pollUnreadMessageAPI = () => {
    console.log('HI');
    if (this.props && this.props.features) {
      const utilityService = new Utility();
      const messagingPersonStatus = utilityService.checkStatusExists(this.props.features, 'Messaging');
      const messagingStatus = utilityService.checkStatusExists(this.props.features, 'Messaging_Person');
      if (messagingPersonStatus && messagingStatus) {
        if (!this.messagingIntervalRef) {
          this.getUnreadMessagesCount();
          this.messagingIntervalRef = setInterval(() => {
            if (this.unreadPollCntr === 0) {
              this.getUnreadMessagesCount();
            }
          }, Constant.MESSAGE_POLL_INTERVAL);
        }
      } else {
        if (this.messagingIntervalRef) {
          clearInterval(this.messagingIntervalRef);
          this.messagingIntervalRef = null;
        }
      }
    }
  };

  startAPIPolling = (interval = Constant.DEFAULT_POLL_INTERVAL) => {
    console.warn(`API will be polled after every ${interval} milliseconds.`);
    this.userTokenPollIntervalRef = setInterval(() => {
      if (this.pollCntr === 0) {
        this.getUserToken();
        this.getFeatures();
      }
    }, interval);
  };

  /**
   * @name getUnreadMessageFromAPI
   * @desc A wrapper method that is getting unread message count
   * if Messaging & Messaging_Person feature flag is ON at that location.
   * @return {void}
   */
  getUnreadMessageFromAPI = () => {
    if (this.props && this.props.features) {
      const utilityService = new Utility();
      const messagingPersonStatus = utilityService.checkStatusExists(this.props.features, 'Messaging');
      const messagingStatus = utilityService.checkStatusExists(this.props.features, 'Messaging_Person');
      if (messagingPersonStatus && messagingStatus) {
        this.getUnreadMessagesCount();
      } else {
        if (this.messagingIntervalRef) {
          clearInterval(this.messagingIntervalRef);
          this.messagingIntervalRef = null;
        }
      }
    }
  };

  /**
   * @name pollFnFUnreadMessageAPI
   * @desc A wrapper method that is polling unread messages on interval as passed in param.
   * @return {void}
   */
  pollFnFUnreadMessageAPI = () => {
    if (this.props && this.props.features) {
      const utilityService = new Utility();
      const messagingStatus = utilityService.checkStatusExists(this.props.features, 'FF_Messaging');
      if (messagingStatus) {
        if (!this.messagingFnFIntervalRef) {
          this.getFriendFamilyUnreadMessagesCount();
          if (Constant.POLL_CONFIG.USE_POLLING) {
            this.messagingFnFIntervalRef = setInterval(() => {
              if (!this.isFnFUnreadCountCalling) {
                this.getFriendFamilyUnreadMessagesCount();
              }
            }, Constant.MESSAGE_POLL_INTERVAL);
          }
        }
      } else {
        if (this.messagingFnFIntervalRef) {
          clearInterval(this.messagingFnFIntervalRef);
          this.messagingFnFIntervalRef = null;
        }
      }
    }
  };

  /**
   * @name queryUser
   * @desc Queries for user data from backend service.
   * @return {void}
   */
  queryUser = async () => {
    try {
      const userInfo = await this.userService.queryUser();
      store.dispatch(currentUser(userInfo));
      store.dispatch(setLocale({ locale: userInfo.preferredLanguage || 'en' }));
      this.pollFnFUnreadMessageAPI();
      if (userInfo && !userInfo.onboarded) {
        this.setState({ showOnboarding: true });
      }
      if (userInfo.careerPreparednessLevel) {
        this.props.setRecommendedJobZone(userInfo.careerPreparednessLevel);
      }
      this.props.setAppLevelApiLoading(false);
    } catch (e) {}
  };

  /**
   * @name getInterestProfilerAnswers
   * @desc Fetches the interest profiler answers
   * @return {void}
   */
  getInterestProfilerAnswers = async () => {
    try {
      const answersString = await this.interestProfilerService.getAnswersString();
      this.props.setInterestProfilerAnswersString(answersString);
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @name getPDQAssessmentStatusAndScores
   * @desc Fetches the pdq assessment status and scores
   * @return {void}
   */
  getPDQAssessmentStatusAndScores = async () => {
    try {
      const isFeatureEnabled = this.utilityService.checkStatusExists(
        this.props.features,
        Constant.FEATURES_ENUM.MILESTONE_MAPPER
      );

      if (!isFeatureEnabled) {
        this.props.setPDQAssessmentStatus(null);
        return;
      }

      const { data: assessmentData } = await this.pdqService.getPDQAssessmentStatus();
      if (assessmentData?.payload) {
        const { assessmentStatus } = assessmentData.payload;
        if (assessmentStatus === Constant.PDQ_ENUM.COMPLETED) {
          const { data: scoresData } = await this.pdqService.getPDQScores();
          if (scoresData?.payload) {
            const pdqScores = scoresData.payload;
            this.props.setPDQScores(pdqScores);
          }
        }
        this.props.setPDQAssessmentStatus(assessmentStatus);
      }
    } catch (e) {
      console.log(e);
    }
  };

  /**
   * @name getEarnTokenRules
   * @desc Fetches facility / location rules for earn token.
   * @return {void}
   */
  getEarnTokenRules = () => {
    if (
      this.earnTokenAjaxCall ||
      !this.utilityService.checkStatusExists(this.props.features, 'Incentive_Engine') ||
      this.props.rulesCollection
    ) {
      return false;
    }
    this.earnTokenAjaxCall = true;

    this.userService
      .getEarnTokenRules()
      .then((_successLog) => {
        this.props.setServiceRules(_successLog);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  /**
   * @name getFeatures
   * @param {boolean} hasLoader Flag to show loader or not
   * @desc Fetches play area status
   * @return {Promise}
   */
  getFeatures = (hasLoader = false) => {
    const utilityService = new Utility();
    let queryString = '';
    if (!hasLoader) {
      queryString = '?noLoader=true';
    }
    this.pollCntr++;
    this.userService
      .getFeatures(queryString)
      .then((features) => {
        this.props.updateFeatures(features);
        const Learner_Portal_Access = utilityService.checkStatusExists(features, 'Learner_Portal_Access');
        const Learner_Portal_Access_Schedule = utilityService.checkStatusExists(features, 'Learner_Portal_Access_Schedule');
        this.getRequiredForms(features, utilityService);
        if (Learner_Portal_Access && Learner_Portal_Access_Schedule) {
          this.userService.getLearnerSchedule(queryString).then((schedule) => {
            this.props.setLearnerSchedule(schedule);
            this.pollCntr--;
            this.closeToast();
          });
        } else {
          this.props.setLearnerSchedule([]);
          this.pollCntr--;
          this.closeToast();
        }
      })
      .catch((error) => {
        this.pollCntr--;
      });
  };

  /**
   * @param {array} features
   * @param {Class} utilityService
   * @desc Checks if there's any required forms ONLY if forms is enabled
   * for a learner.
   * @return {void}
   */
  getRequiredForms = (features, utilityService) => {
    const isFormsEnabled = utilityService.checkStatusExists(features, 'Forms');
    this.props.setFormsEnabled(isFormsEnabled);
    if (isFormsEnabled) {
      this.userService.getRequiredFormsCount().then((response) => {
        const isRequiredFormExist = response.pendingSubmissionCount > 0;
        this.props.setRequiredFormExistance(isRequiredFormExist);
        this.props.setRequiredFormExistOnLogin(isRequiredFormExist);
        if (isRequiredFormExist) {
          this.props.history.push('/forms');
        }
      });
    }
  };

  /**
   * @name getUserToken
   * @desc Fetches user token from user-service.
   * @return {void}
   */
  getUserToken = () => {
    this.pollCntr++;
    this.userService.getUserToken().then(
      (_successLog) => {
        if (this.props.lastActivityDate !== _successLog.lastActivityDate) {
          this.getUserTransaction();
        }

        this.props.setUserTokenBalance(_successLog);
        this.pollCntr--;
        this.closeToast();
      },
      () => {
        this.pollCntr--;
      }
    );
  };

  /**
   * @name getUserTransaction
   * @desc Fetches user transaction from user-service.
   * @return {void}
   */
  getUserTransaction = () => {
    this.userService.getUserTransaction().then((_successLog) => {
      this.props.setUserTransaction(_successLog);
    });
  };

  /**
   * @name getFriendFamilyUnreadMessagesCount
   * @desc Fetches friends and family user messages count.
   * @return {void}
   */
  getFriendFamilyUnreadMessagesCount = () => {
    const { userDetail } = this.props;
    if (!userDetail) {
      return;
    }
    this.isFnFUnreadCountCalling = true;
    this.userService.getFnFUnreadMessagesCount(parseInt(userDetail.userId)).then((_successLog) => {
      const { payload } = _successLog;
      this.props.setFriendsFamilyUnreadData(payload);
      this.isFnFUnreadCountCalling = false;
    });
  };

  /**
   * @name getUnreadMessagesCount
   * @desc Fetches user messages count.
   * @return {void}
   */
  getUnreadMessagesCount = () => {
    this.unreadPollCntr++;
    this.userService
      .getUnreadMessagesCount()
      .then((_successLog) => {
        const { totalUnreadMessageThreadCount: count, totalUnreadAnnouncementCount: announcementCount } = _successLog;
        this.props.setUnreadMessagesCount(count);
        this.props.setUnreadAnnouncementCount(announcementCount);
        this.unreadPollCntr--;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  handleLogInOut = () => {
    const { keycloak, auth0 } = this.props;
    recordRUMEvent(RUM_EVENTS_ENUM.LOGOUT_INITIATED, { type: 'manual-logout' });
    if (this.state.authorize) {
      this.tracker.initiateServerRequest(true);
      logoutFromPartnerSystems()
        .then((_successLog) => {
          Utility.manageLogout('set');
          handleLogout(keycloak, auth0);
        })
        .catch((error) => {
          window.location.reload();
        });
    } else {
      Utility.manageLogout('set');
      handleLogout(keycloak, auth0);
    }
  };

  /**
   * @name onUnload
   * @desc beforeunload event callback.
   */
  onUnload = () => {
    this.utilityService.closeAllExploreChildWindow();
    this.tracker.initiateServerRequest(false, true);
  };

  handleFnFUnreadCount() {
    let self = this;
    registerAction({
      event: 'new_fnf_message_for_unread_count',
      action: function (payload) {
        console.log('new_fnf_message_for_unread_count', payload);
        const newMessage = { ...payload };
        const { unreadData } = self.props.friendsAndFamily;
        if (unreadData.relationships) {
          const currentRelationIndex = unreadData.relationships.findIndex(
            (_relationship) => _relationship.contact.personId === newMessage.senderPersonId
          );
          if (currentRelationIndex > -1) {
            unreadData['totalUnreadCount'] = unreadData['totalUnreadCount'] + 1;
            unreadData.relationships[currentRelationIndex].learner.unreadCount =
              unreadData.relationships[currentRelationIndex].learner.unreadCount + 1;
            self.props.setFriendsFamilyUnreadData(unreadData);
          }
        }
      },
    });
  }

  componentDidMount() {
    this.userService.callingDidMount();
    window.onunload = this.onUnload;
    this.handleFnFUnreadCount();
  }

  componentWillUnmount() {
    clearInterval(this.userTokenPollIntervalRef);
    this.userService.callingWillUnmount();
  }

  async componentDidUpdate(prevProps) {
    this.getEarnTokenRules();

    if (prevProps.features !== this.props.features) {
      this.getPDQAssessmentStatusAndScores();
    }

    /**
     * Check when this page is navigate by public routes then don't verify authentication process
     */
    if (isPublicRoute(this.routes)) {
      return;
    }

    /**
     * Check if auth check if already performed and don't perform it again
     */
    if (this.state.authCheckPerformed) {
      return;
    }

    if (!this.state.performingAuthCheck) {
      this.performAuthCheck();
    }
  }

  performAuthCheck = async () => {
    this.setState({
      performingAuthCheck: true,
    });

    recordRUMEvent(RUM_EVENTS_ENUM.PORTAL_LAUNCH);

    const { keycloak, auth0 } = this.props;
    const authResponse = await handleAuthentication(keycloak, auth0);

    if (authResponse.error) {
      if (authResponse.error.message === AUTH_CONSTANTS.AUTH_ERROR_TYPES.USER_BLOCKED) {
        return authResponse.redirectToLogin({
          'ext-login-error-en': AUTH_CONSTANTS.AUTH_ERROR_MESSAGES.USER_BLOCKED_EN,
          'ext-login-error-es': AUTH_CONSTANTS.AUTH_ERROR_MESSAGES.USER_BLOCKED_ES,
        });
      }
      recordRUMEvent(RUM_EVENTS_ENUM.AUTH0_SDK_ERROR, { error: authResponse.error });
      this.setState({
        authorize: false,
        authCheckPerformed: true,
        hasAuthError: true,
        performingAuthCheck: false,
      });
      return;
    } else if (!authResponse.isAuthenticated) {
      const traceId = generateAuthSessionCorrelation();
      recordRUMEvent(RUM_EVENTS_ENUM.AUTH_NEEDED, { traceId });
      return authResponse.redirectToLogin();
    } else if (!authResponse.isAuthorized) {
      recordRUMEvent(RUM_EVENTS_ENUM.UNAUTHORIZED_LOGIN);
      this.setState({
        authorize: false,
        authCheckPerformed: true,
        performingAuthCheck: false,
      });
      return;
    }

    recordRUMEvent(RUM_EVENTS_ENUM.AUTH_SESSION_FOUND);

    Utility.USER_DATA = authResponse.user;
    store.dispatch(
      currentUser({
        locationId: authResponse.user?.locationId || '',
        name: authResponse.user?.name || '',
      })
    );

    this.callAppLevelAPIS(authResponse.user.preferred_username, authResponse.user.auth_time);

    if (getCookie('session_id') !== authResponse.user.sessionId) {
      this.userService.refreshSSOAttributes();
      setCookie('session_id', authResponse.user.sessionId);
    }

    const lastVisitedPath = sessionStorage.getItem(AUTH_CONSTANTS.LAST_VISITED_PATH);
    if (lastVisitedPath) {
      this.props.history.push(lastVisitedPath);
    } else if (window.location.pathname === '/') {
      this.props.history.push('/home');
    }

    this.setState({
      authorize: true,
      authCheckPerformed: true,
      hasAuthError: false,
      performingAuthCheck: false,
    });
  };

  isLearnosityAssessmentPage = () => {
    const path = '/learnosity/assessment';
    return window.location.pathname.includes(path);
  };

  renderLayout = (logInOutText) => {
    let isClosed = false;
    this.props.learnerSchedule.forEach((items) => {
      items.forEach((schedule) => {
        schedule.slots.forEach((slots) => {
          if ((slots.isClosed && slots.isActive) || isClosed) {
            isClosed = true;
          }
        });
      });
    });

    if (isClosed) {
      return (
        <>
          <Header handleLogout={this.handleLogInOut} logInOutText={logInOutText} isClosed={isClosed} />
          <div
            style={{
              padding: '75px 0',
              marginTop: '50px',
            }}
          >
            <Closed
              align="center"
              mainText={<FormattedMessage id="closedLearnerScheduleMainText" />}
              subText={<FormattedMessage id="checkReopening" />}
              viewScheduleBtnText={<FormattedMessage id="viewLearnerSchedule" />}
              viewScheduleButton={false}
              schedule={this.props.learnerSchedule}
              title={<FormattedMessage id="learnerPortalScheduleDialogTitle" />}
            />
          </div>
        </>
      );
    }

    if (this.isLearnosityAssessmentPage()) {
      return <LearnosityAssessment history={this.props.history} />;
    }

    return (
      <Layout handleLogout={this.handleLogInOut} logInOutText={logInOutText} isClosed={isClosed} {...this.props}>
        {this.state.showOnboarding && <Onboarding hideOnboarding={this.hideOnboarding} />}
        {this.props.children}
      </Layout>
    );
  };

  authorizedRenderer = (logInOutText) => {
    return this.state.authorize && !this.props.openSessionExpire ? (
      this.renderLayout(logInOutText)
    ) : (
      <Error
        errorCode={Constant.ERROR.AUTHORIZE_ERROR.STATUS}
        errorMessage={Constant.ERROR.AUTHORIZE_ERROR.MESSAGE}
        logInOutText={logInOutText}
        handleLogout={this.handleLogInOut}
      />
    );
  };

  closeToast = () => {
    if (this.props.toast.isOpen) {
      this.props.toggleToast({
        ...this.props.toast,
        isOpen: false,
      });
    }
  };

  render() {
    const { keycloak, auth0, isAccessDenied } = this.props;
    const isLogoutInitiated = !!Utility.manageLogout('get');
    const logInOutText = 'Logout';

    return (
      <div>
        {this.state.authCheckPerformed && (
          <>
            <ValidateSession />
            {!Constant.POLL_CONFIG.USE_POLLING && <StaffMessageSocket />}
            <IdleTimeout />
            <SessionExpireView handleLogInOut={this.handleLogInOut} />
            {isAuthenticated(keycloak, auth0) && this.authorizedRenderer(logInOutText)}
            {!isLogoutInitiated && !isAuthenticated(keycloak, auth0) && (
              <Error
                errorCode={Constant.ERROR.SESSION_ERROR.STATUS}
                errorMessage={Constant.ERROR.SESSION_ERROR.MESSAGE}
                logInOutText={logInOutText}
                handleLogout={this.handleLogInOut}
              />
            )}
            {isAccessDenied && <Error isAccessDenied={isAccessDenied} handleLogout={this.handleLogInOut} />}
            {this.state.hasAuthError && (
              <Error
                errorCode={Constant.ERROR.GENERAL_ERROR.STATUS}
                errorMessage={Constant.ERROR.GENERAL_ERROR.MESSAGE}
                logInOutText={logInOutText}
                handleLogout={this.handleLogInOut}
              />
            )}
            <BackToTopButton element={window} />
          </>
        )}
      </div>
    );
  }
}

AuthContainer.propTypes = {
  keycloak: PropTypes.object,
};

const mapStateToProps = (state) => {
  return {
    features: state.app.features,
    learnerSchedule: state.app.learnerSchedule,
    openSessionExpire: state.app.openSessionExpire,
    toast: state.app.toast,
    friendsAndFamily: state.app.friendsAndFamily,
    userDetail: state.app.userDetail,
    lastActivityDate: state.app.lastActivityDate,
    rulesCollection: state.app.rulesCollection,
    announcementCount: state.app.announcementCount,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    toggleLoader: (isLoading) => dispatch(appStore.showHideLoader({ isLoading })),
    setUserTokenBalance: (payload) => dispatch(setUserTokenBalance(payload)),
    setUserTransaction: (transactions) => dispatch(setUserTransaction(transactions)),
    updateFeatures: (featureCollection) => dispatch(appStore.setFeatures(featureCollection)),
    setServiceRules: (rules) => dispatch(earnStore.setRules(rules)),
    setFormsEnabled: (isFormsEnabled) => dispatch(appStore.setFormsEnabled(isFormsEnabled)),
    setUnreadMessagesCount: (count) => dispatch(appStore.setUnreadMessagesCount(count)),
    setUnreadAnnouncementCount: (count) => dispatch(appStore.setUnreadAnnouncementCount(count)),
    setRequiredFormExistance: (isRequiredFormExist) => dispatch(appStore.setRequiredFormExistance(isRequiredFormExist)),
    setRequiredFormExistOnLogin: (isRequiredFormExistOnLogin) =>
      dispatch(appStore.setRequiredFormExistOnLogin(isRequiredFormExistOnLogin)),
    setLearnerSchedule: (schedule) => dispatch(appStore.setLearnerSchedule(schedule)),
    toggleToast: (config) => dispatch(appStore.toggleToast(config)),
    setFriendsFamilyUnreadData: (unreadData) => dispatch(appStore.setFriendsFamilyUnreadData(unreadData)),
    setInterestProfilerAnswersString: (string) => dispatch(interestProfilerStore.setAnswersString(string)),
    setRecommendedJobZone: (zone) => dispatch(interestProfilerStore.setRecommendedJobZone(zone)),
    setPDQScores: (scores) => dispatch(pdqAssessmentStore.setPDQScores(scores)),
    setPDQAssessmentStatus: (status) => dispatch(pdqAssessmentStore.setPDQAssessmentStatus(status)),
    setAppLevelApiLoading: (isLoading) => dispatch(appStore.setAppLevelApiLoading(isLoading)),
  };
};

export default withAuth0(withKeycloak(withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthContainer))));
