import { Http } from '../../../../core';
import { Constant } from '../../../../shared';

const IMAGE_PATH = '/assets/img/mini-quiz-card-image.png';

/**
 * Function to get mini quiz questions for interest profiler
 *
 * @returns {Promise<Array>} Returns array of questions with their options
 */
export const getMiniQuizQuestions = () => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get('/services/interest-profiler/mini/v1/questions')
      .then((response) => {
        const data = prepareMiniQuestionData(response?.data?.payload?.questions);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCareerClusterQuestions = (riasecCode) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get(`/services/interest-profiler/v1/questions?riasecCode=${riasecCode}`)
      .then((response) => {
        const data = prepareCareerClusterData(response?.data?.payload?.questions);
        resolve(data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getContentRecommendations = (clusterCode) => {
  return new Promise((resolve, reject) => {
    Http.HYREUS_REQUEST.get(`/services/content-recommendation/v1?cluster-code=${clusterCode}`)
      .then((response) => {
        const processedData = response?.data?.payload;
        resolve(processedData);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to prepare mini quiz question data
 *
 * @param {Array} data - The data to be prepared
 * @returns {Array} Returns the prepared data
 */

const prepareMiniQuestionData = (data) => {
  return data.map((item) => ({
    questionId: item.index,
    description: item.text,
    options: item.options.map((option) => ({
      id: option.index,
      description: option.text,
      value: option.value,
      imageUrl: option.thumbnailUri ? `${option.thumbnailUri}` : `${Constant.CDN_BASE_URL}${IMAGE_PATH}`,
    })),
  }));
};

/**
 * Function to prepare career cluster question data
 *
 * @param {Array} data - The data to be prepared
 * @returns {Array} Returns the prepared data
 */
const prepareCareerClusterData = (data) => {
  return data.map((item) => ({
    questionId: item.index,
    description: item.text,
    helperText: item.helperText,
    options: item.options.map((option) => ({
      id: option.index,
      description: option.text,
      imageUrl: option.thumbnailUri ? `${option.thumbnailUri}` : `${Constant.CDN_BASE_URL}${IMAGE_PATH}`,
      value: option.value,
      hidden: option.hidden,
    })),
  }));
};
