import moment from 'moment';

import { Http } from '../../../../core/services';
import { setUserTokenBalance, store } from '../../../../core/store';
import { Constant, UserService } from '../../../../shared';
import { Tracker } from '../../../../core/services/tracker/tracker';

const weekOfDaysScheduleData = JSON.stringify([
  {
    dayOfWeek: 'MONDAY',
    slots: [],
  },
  {
    dayOfWeek: 'TUESDAY',
    slots: [],
  },
  {
    dayOfWeek: 'WEDNESDAY',
    slots: [],
  },
  {
    dayOfWeek: 'THURSDAY',
    slots: [],
  },
  {
    dayOfWeek: 'FRIDAY',
    slots: [],
  },
  {
    dayOfWeek: 'SATURDAY',
    slots: [],
  },
  {
    dayOfWeek: 'SUNDAY',
    slots: [],
  },
]);

const DAYSLIST = ['MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY', 'SUNDAY'];

export class PlayMainService {
  OFF = 0;
  ON = 1;
  IN_SCHEDULE = 2;
  NOT_IN_SCHEDULE = 3;
  NO_SCHEDULE_DEFINED = 4;
  TIME_REMAINING = 0;
  MAX_SCHEDULE = 1;
  static userDateTime = null;
  static customDateIntevalRef = null;
  videoSchedule = null;
  gameSchedule = null;
  radioSchedule = null;
  playSchedule = null;

  /**
   * @name getCurrentDayRedemptions
   * @desc Fetches current day redemptions and create a payload
   *       that can be used on view in order to check if facility
   *       is enabled or not.
   * @return {Promise}
   */
  getCurerntDayRedemptions = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/transaction/redemptions?date=${Date.now()}`)
        .then((_successLog) => {
          const { data } = _successLog;
          if (data) {
            const redeem = {
              video: !!data.find((redeemLog) => redeemLog.featureName === 'Video'),
              game: !!data.find((redeemLog) => redeemLog.featureName === 'Game'),
              radio: !!data.find((redeemLog) => redeemLog.featureName === 'Radio'),
            };
            resolve({
              redeem,
            });
          } else {
            resolve({
              redeem: {
                video: false,
                game: false,
                radio: false,
              },
            });
          }
        })
        .catch((error) => console.log(error));
    });
  };
  /**
   * @name getScheduleTimeRemaing
   * @param {array[]} scheduleCollection
   * @param {string} type Type of output required.
   * @desc If schedule is on then computation is performed on
   *       schedule collection in order to get schedule remaining time.
   * @return {string}
   */
  getScheduleTimeRemaining = (scheduleCollection = [], type) => {
    const momentCollection = [];
    if (scheduleCollection.length === 0) {
      return false;
    }
    const scheduleFlatList = scheduleCollection.reduce((prev, next) => [...prev, ...next]);

    scheduleFlatList.forEach((_schedule) => {
      const isCurrentDay = moment(UserService.userDateTime).format('dddd').toUpperCase() === _schedule.dayOfWeek;
      if (isCurrentDay) {
        _schedule.slots.forEach((_slotData) => {
          if (_slotData.start && _slotData.end && _slotData.isActive) {
            momentCollection.push(moment.unix(_slotData.start));
            momentCollection.push(moment.unix(_slotData.end));
          }
        });
      }
    });
    switch (type) {
      case this.TIME_REMAINING:
        if (momentCollection.length > 0) {
          var ms = moment(moment.max(momentCollection)).diff(moment(UserService.userDateTime));
          var duration = moment.duration(ms);
          var durationLeft = Math.floor(duration.asHours()) + moment(ms).format(':mm:ss');
          if (ms > 0) {
            return durationLeft;
          }
        }
        break;
      case this.MAX_SCHEDULE:
        return moment.max(momentCollection);

      default:
        return null;
    }

    return false;
  };

  /**
   * @name getPlayMeta
   * @desc Meta information for top area of play. Achievements / Pathways card
   * information can be found in this response.
   * @return {Promise}
   */
  getPlayMeta = () => {
    return new Promise((resolve, reject) => {
      const lastTransactions = Http.REQUEST.get('/transaction/lastTokenActivity');
      const assignedCourseAPI = Http.REQUEST.get('/resource/recentCourseBundle');
      Promise.all([lastTransactions, assignedCourseAPI]).then(
        (_successLog) => {
          let payload = Object.assign({}, _successLog[0].data, _successLog[1].data);
          resolve(payload);
        },
        (_errorLog) => resolve({})
      );
    });
  };

  /**
   * @name checkIsClosed
   * @param {string} areaName Name of area whose presence needs to be checked.
   * @desc Checks if area is in close state or not.
   * @return {boolean} isClosed
   */
  checkIsClosed = (areaName) => {
    let isClosed = false;
    let collection = [];
    if (areaName === 'Video' && this.videoSchedule.length > 0) {
      // INVERSING VALUE TO BOOLEAN
      isClosed = !this.getScheduleTimeRemaining(this.videoSchedule, this.TIME_REMAINING);

      collection = this.videoSchedule.reduce((column1, column2) => [...column1, ...column2]);
    } else if (areaName === 'Play' && this.playSchedule.length > 0) {
      // INVERSING VALUE TO BOOLEAN
      isClosed = !this.getScheduleTimeRemaining(this.playSchedule, this.TIME_REMAINING);

      collection = this.playSchedule.reduce((column1, column2) => [...column1, ...column2]);
    } else if (areaName === 'Radio' && this.radioSchedule.length > 0) {
      // INVERSING VALUE TO BOOLEAN
      isClosed = !this.getScheduleTimeRemaining(this.radioSchedule, this.TIME_REMAINING);
      collection = this.radioSchedule.reduce((column1, column2) => [...column1, ...column2]);
    } else if (areaName === 'Game' && this.gameSchedule.length > 0) {
      // INVERSING VALUE TO BOOLEAN
      isClosed = !this.getScheduleTimeRemaining(this.gameSchedule, this.TIME_REMAINING);
      collection = this.gameSchedule.reduce((column1, column2) => [...column1, ...column2]);
    }
    collection.forEach((schedule) => {
      schedule.slots.forEach((slots) => {
        if ((slots.isClosed && slots.isActive) || isClosed) {
          isClosed = true;
        }
      });
    });

    return isClosed;
  };

  /**
   * @name getAreaType
   * @param {string} areaName Name of area for which calculation needs to be done.
   * @param {string} areaScheduleName Schedule name of area for which calculation needs to be done.
   * @param {array} settingsCollection Collection of area settings.
   * @desc Calculates area type based upon areaScheduleName & areaName.
   * @return {number} type
   */
  getAreaType = (areaName, areaScheduleName, settingsCollection = []) => {
    let payload = {
      type: null,
      price: null,
    };
    const area = settingsCollection.filter((data) => data.featureName === areaName)[0];
    const areaSchedule = settingsCollection.filter((data) => data.featureName === areaScheduleName)[0];
    if (area || areaSchedule) {
      payload['price'] = area.price;
      const isClosed = this.checkIsClosed(areaName);

      if (!area.status) {
        payload['type'] = this.OFF;
      } else if (isClosed) {
        payload['type'] = this.NOT_IN_SCHEDULE;
      } else if (area.status && areaSchedule.status) {
        payload['type'] = this.ON;
      } else if (area.status && !areaSchedule.status) {
        payload['type'] = this.NO_SCHEDULE_DEFINED;
      }
    }

    return payload;
  };

  /**
   * @name redeemFeature
   * @param {number} remainingBalance
   * @desc Redeem's feature and locally updates remaining balance.
   * @return {Promise}
   */
  redeemFeature = async (tabName, remainingBalance, setDialogVisibility, updateRedeemState) => {
    let featureName = '';
    switch (tabName) {
      case Constant.PLAY.VIDEO:
        featureName = 'Video';
        break;
      case Constant.PLAY.GAME:
        featureName = 'Game';
        break;
      case Constant.PLAY.RADIO:
        featureName = 'Radio';
        break;
      default:
        break;
    }
    const redeemFeaturePayload = {
      featureName,
      createdDate: Date.now(),
    };
    await Http.REQUEST.post('/transaction/redeemFeature', redeemFeaturePayload);
    return new Promise((resolve, reject) => {
      updateRedeemState({
        [featureName.toLowerCase()]: true,
      });
      store.dispatch(
        setUserTokenBalance({
          balance: remainingBalance,
        })
      );

      setDialogVisibility(false);
    });
  };

  /**
   * @name fillScheduleEmptyData
   * @param {array} scheduleSet Set of schedule from server.
   * @desc Fills empty data into schedule and makes it available for all days.
   * @return {array} modifiedSet
   */
  fillScheduleEmptyData = (scheduleSet) => {
    let tempData = JSON.parse(weekOfDaysScheduleData);
    return tempData.map((data) => {
      const _schedule = scheduleSet.find((_data) => {
        return _data.dayOfWeek === data.dayOfWeek;
      });
      if (_schedule) {
        data.slots = _schedule.slots;
      } else {
        data.slots = [
          {
            isClosed: true,
          },
        ];
      }
      return data;
    });
  };

  /**
   * @name getUserTimeMeta
   * @desc Fetches user time from third party service, in order to make sure
   *        that no throtling between time is done.
   * @return {Promise}
   */
  getUserTimeMeta = () => {
    return new Promise((resolve) => {
      resolve({
        datetime: UserService.userDateTime,
      });
    });
  };

  /**
   * @name destroyInterval
   * @desc A wrapper method to destroy all intervals
   * @return {void}
   */
  destroyInterval = () => {
    clearInterval(PlayMainService.customDateIntevalRef);
    PlayMainService.customDateIntevalRef = null;
  };

  /**
   * @name formatScheduleData
   * @param {array} scheduleSet
   * @desc Formats schedule data and adds relevant key if required.
   * @return {array} modifiedScheduleSet
   */
  formatScheduleData = (scheduleSet) => {
    const currentTime = moment(UserService.userDateTime);
    let nextActiveSlot;
    scheduleSet = this.fillScheduleEmptyData(scheduleSet);
    return scheduleSet.map((schedule) => {
      let isActiveFound = false;
      schedule.slots = schedule.slots.map((slot) => {
        // ASSUMING THAT DAY IS MARKED AS
        // DE-ACTIVE FROM SERVER AND RETURNING
        // RESPONSE
        if (slot.isActive && slot.isClosed) {
          return slot;
        }

        if (!slot.isClosed && !nextActiveSlot) {
          nextActiveSlot = { day: schedule.dayOfWeek, slot, default: true };
          schedule.nextActiveSlot = nextActiveSlot;
        }

        if (slot.start && slot.end) {
          const startTime = moment.unix(slot.start);
          const endTime = moment.unix(slot.end);
          isActiveFound =
            (currentTime.isBetween(startTime, endTime) && currentTime.format('dddd').toUpperCase() === schedule.dayOfWeek) ||
            isActiveFound;
          slot['isActive'] =
            currentTime.isBetween(startTime, endTime) && currentTime.format('dddd').toUpperCase() === schedule.dayOfWeek;
          const currentDayIndex = DAYSLIST.findIndex((item) => item === currentTime.format('dddd').toUpperCase());
          const slotDayIndex = DAYSLIST.findIndex((item) => item === schedule.dayOfWeek);
          if (
            ((currentTime.isBefore(startTime) && currentDayIndex === slotDayIndex) || currentDayIndex < slotDayIndex) &&
            nextActiveSlot &&
            nextActiveSlot.default &&
            !slot.isClosed
          ) {
            nextActiveSlot = { day: schedule.dayOfWeek, slot, default: false };
            schedule.nextActiveSlot = nextActiveSlot;
          }
        }

        return slot;
      });
      const isCurrentDay = currentTime.format('dddd').toUpperCase() === schedule.dayOfWeek;
      if (schedule.slots[0].isClosed && isCurrentDay) {
        schedule.slots[0]['isActive'] = true;
      } else {
        if (!isActiveFound && isCurrentDay) {
          schedule.slots.unshift({
            isClosed: true,
            isActive: true,
          });
        }
      }
      return schedule;
    });
  };

  /**
   * @name prepareSchedule
   * @param {string} featureKey Key of feature whose data needs to
   *                            be prepared.
   * @param {array} schedules Collection of schedules fetched from server.
   * @desc Prepares schedule for view rendering purpose. Performs
   *        computation and fills empty data where data is not available.
   * @return {array}
   */
  prepareSchedule = (featureKey, schedules) => {
    const foundSchedule = schedules.find((schedule) => schedule.featureName === featureKey);
    if (foundSchedule) {
      let schedule = Object.assign([], this.formatScheduleData(foundSchedule.schedules));
      const noOfItemsPerSet = Math.ceil(schedule.length / 2);
      const firstColumnSet = schedule.slice(0, noOfItemsPerSet);
      const secondColumnSet = schedule.slice(noOfItemsPerSet, schedule.length);
      return {
        featureKey,
        items: [firstColumnSet, secondColumnSet],
      };
    }

    return {
      featureKey,
      items: [],
    };
  };

  /**
   * @name parseServerDateToCurrentDate
   * @param {string} dateTime
   * @desc Paress server date to current datetime.
   * @return {number} unix
   */
  parseServerDateToCurrentDate = (dateTime) => {
    const serverDateTime = moment.utc(dateTime).format('HH:mm:ss');
    return moment(moment().format('YYYY-MM-DD ') + serverDateTime).unix();
  };

  /**
   * @name refineScheduleData
   * @param {array} scheduleCollection
   * @desc Re-fines schedule data, adds / remove key & alters values as well.
   * @return {array} refinedData
   */
  refineScheduleData = (scheduleCollection) => {
    return scheduleCollection.map((feature) => {
      feature.schedules = feature.schedules.map((schedule) => {
        schedule.slots = schedule.slots.map((slot) => {
          const payload = {
            start: this.parseServerDateToCurrentDate(slot.startTime),
            end: this.parseServerDateToCurrentDate(slot.endTime),
          };
          if (!slot.isActive) {
            payload['isClosed'] = true;
          }
          return payload;
        });
        return schedule;
      });
      return feature;
    });
  };

  /**
   * @name getAllSchedule
   * @desc Fetches all schedule from server and further prepares
   * for view rendering purpose.
   * @return {Promise}
   */
  getAllSchedule = (queryParams) => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/feature/location/schedule${queryParams}`)
        .then((_successLog) => {
          const refinedScheduleData = this.refineScheduleData(_successLog.data);
          this.videoSchedule = this.prepareSchedule('Video_Schedule', refinedScheduleData).items;
          this.playSchedule = this.prepareSchedule('Play_Schedule', refinedScheduleData).items;
          this.radioSchedule = this.prepareSchedule('Radio_Schedule', refinedScheduleData).items;
          this.gameSchedule = this.prepareSchedule('Game_Schedule', refinedScheduleData).items;
          resolve({
            videoSchedule: this.videoSchedule,
            playSchedule: this.playSchedule,
            radioSchedule: this.radioSchedule,
            gameSchedule: this.gameSchedule,
          });
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * @name otherTabNotActive
   * @param {object} status
   * @param {string} currentPathname
   * @param {array} tabsCollection
   * @desc Checks if tab is not active & pathname is valid.
   * @return {boolean}
   */
  otherTabNotActive = (status, tabsCollection) => {
    let isNotActive = true;
    tabsCollection.forEach((tabKey) => {
      // CHECKING VIDEO W.R.T ITS STATUS
      if (tabKey === 'video' && status[tabKey].type !== this.OFF) {
        isNotActive = false && isNotActive;
      }
      // CHECKING RADIO W.R.T ITS STATUS
      if (tabKey === 'radio' && status[tabKey].type !== this.OFF) {
        isNotActive = false && isNotActive;
      }
      // CHECKING GAME W.R.T ITS STATUS
      if (tabKey === 'games' && status[tabKey].type !== this.OFF) {
        isNotActive = false && isNotActive;
      }
    });
    return isNotActive;
  };

  /**
   * @name navigateTab
   * @param {object} status
   * @param {object} history Browser history object.
   * @desc Navigate's toward active tab.
   * @return {void}
   */
  navigateTab = (status, history) => {
    const currentPathname = window.location.pathname; // INNER IF B/C PARENT IF SHOULD BREAK
    if (status.videos.type !== this.OFF && this.otherTabNotActive(status, ['games', 'radio'])) {
      if (currentPathname !== Tracker.RouteEnum.entertain.video) {
        history.push(Tracker.RouteEnum.entertain.video);
      }
    } else if (status.games.type !== this.OFF && this.otherTabNotActive(status, ['videos', 'radio'])) {
      if (currentPathname !== Tracker.RouteEnum.entertain.game) {
        history.push(Tracker.RouteEnum.entertain.game);
      }
    } else if (status.radio.type !== this.OFF && this.otherTabNotActive(status, ['games', 'videos'])) {
      if (currentPathname !== Tracker.RouteEnum.entertain.radio) {
        history.push(Tracker.RouteEnum.entertain.radio);
      }
    } else {
      if (currentPathname !== Tracker.RouteEnum.entertain.entertain) {
        history.push(Tracker.RouteEnum.entertain.entertain);
      }
    }
  };

  /**
   * @name getPlayStatus
   * @param {string} queryParams Query parameters.
   * @desc Status that tells that if play area is on / off, videos
   * are on / off, radio is on / off.
   * @return {Promise}
   */
  getPlayStatus = (queryParams, history) => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/feature/locationFeatures${queryParams}`).then(
        (_successLog) => {
          this.getUserTimeMeta().then((_timeResponse) => {
            this.getAllSchedule(queryParams).then((_scheduleResponse) => {
              const payload = {
                play: this.getAreaType('Play', 'Play_Schedule', _successLog.data),
                videos: this.getAreaType('Video', 'Video_Schedule', _successLog.data),
                games: this.getAreaType('Game', 'Game_Schedule', _successLog.data),
                radio: this.getAreaType('Radio', 'Radio_Schedule', _successLog.data),
              };
              if (payload.play.type === this.OFF) {
                payload.videos.type = this.OFF;
                payload.games.type = this.OFF;
                payload.radio.type = this.OFF;
              } else if (payload.play.type === this.NO_SCHEDULE_DEFINED) {
                // SETTING NO_SCHEDULE_DEFINE FOR
                // ALL TABS ONLY IF THEY ARE NOT OFF
                Object.keys(payload).forEach((key) => {
                  if (payload[key].type !== this.OFF) {
                    payload[key].type = this.NO_SCHEDULE_DEFINED;
                  }
                });
              }

              // this.navigateTab(payload, history);
              resolve({
                status: payload,
                ..._scheduleResponse,
              });
            });
          });
        },
        (_errorLog) => reject(null)
      );
    });
  };

  /**
   * @name getAchievement
   * @desc Fetches achievement data from server.
   * @return {Promise}
   */
  getAchievement = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get('/transaction/lastTokenActivity')
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * @name getIframeQueryParam
   * @param {array} schedule
   * @param {array} playSchedule Play area schedule.
   * @desc Schedule of feature. Based upon end time query param is generated in
   * UTC format.
   * @return {string} queryParam
   */
  getIframeQueryParam = (schedule = [], playSchedule = []) => {
    if (schedule.length === 0 && playSchedule.length === 0) {
      return '';
    }

    let endTime = (schedule.length > 0 ? schedule : playSchedule)
      .reduce((prev, next) => [...prev, ...next])
      .find((schedule) => schedule.slots.find((slot) => slot.isActive))
      .slots.find((slot) => slot.isActive);
    endTime = moment.utc(moment.unix(endTime.end));
    return `?h=${endTime.format('HH')}&m=${endTime.format('mm')}&z=utc`;
  };

  /**
   * @name getFeatureAttributes
   * @desc Fetches feature attributes from server.
   * @return {Promise}
   */
  getFeatureAttributes = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get('/transaction/lastTokenActivity')
        .then((_successLog) => {
          resolve(_successLog.data);
        })
        .catch((error) => reject(error));
    });
  };

  /**
   * @name getPastRedemptions
   * @desc Fetches past redemptions from server.
   * This API should be called once lastActivityDate is updated in order to
   * sync with server.
   * @return {Promise}
   */
  getPastRedemptions = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get('/user/transactions?allTransactionTypes=false&noLoader=true').then(
        (_successLog) => resolve(_successLog.data),
        (_errorLog) => resolve([])
      );
    });
  };

  /**
   * @name getAttributes
   * @desc Fetches attributes from server which contain information like url e.t.c
   * This API should be called after every 12 seconds in order to
   * sync with server.
   * @return {Promise}
   */
  getAttributes = (queryParams = '') => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/location/attributes${queryParams}`).then(
        (_successLog) => resolve(_successLog.data),
        (_errorLog) => resolve([])
      );
    });
  };
}
