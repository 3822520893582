import React, { useEffect, useRef } from 'react';
import { Box, Divider, Grid, withStyles, Typography, Button } from '@material-ui/core';

import styles from './category-details.style';
import { ThumbnailCard, IconCard } from '../../../../shared/components';
import { Constant } from '../../../../shared/services';
import Tools from '../tools/tools';
import { APDSPodcast, ServiceCarousel } from '../';
import ChevronRight from '@material-ui/icons/ChevronRight';
import { FormattedMessage, useIntl } from 'react-intl';
import { ResumeBuilderTile, PersonalityQuizTile } from '../../../../v2/content';

const CategoryDetails = (props) => {
  const CategoryRef = useRef(null);
  const intl = useIntl();

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          props.setActiveNavLink(props.id);
        }
      },
      {
        threshold: 0.5,
      }
    );
    observer.observe(CategoryRef.current);
  }, []);

  const { classes } = props;

  const showThumbnailTiles = [
    Constant.SERVICE_CATEGORIES_ENUM.EDUCATION,
    Constant.SERVICE_CATEGORIES_ENUM.WORKFORCE_SKILLS,
  ].includes(props.id);

  const showIconTiles = [Constant.SERVICE_CATEGORIES_ENUM.PERSONAL_DEVELOPMENT].includes(props.id);

  const showCarousel = [
    Constant.SERVICE_CATEGORIES_ENUM.REHABILITATION,
    Constant.SERVICE_CATEGORIES_ENUM.DOCUMENTRIES,
  ].includes(props.id);

  const showTools = [Constant.SERVICE_CATEGORIES_ENUM.RESOURCES].includes(props.id);

  return (
    <Box
      className={[classes.root, showCarousel ? classes.filledBackground : '', showTools ? classes.whiteBackground : ''].join(
        ' '
      )}
      ref={CategoryRef}
    >
      <Box id={`service-category-${props.id}`} className={classes.titleContainer}>
        <Box className={classes.iconBox}>{props.iconWithProps({ width: 32, height: 32, size: 32 })}</Box>
        <Typography variant="h2" className={classes.title} id={`service-category-${props.id}-title`}>
          {props.name}
        </Typography>
      </Box>

      <Divider className={classes.divider} />

      <Box className={classes.descriptionContainer}>
        <Typography className={classes.description} id={`service-category-${props.id}-desc`}>
          {props.description}
        </Typography>
      </Box>

      {showThumbnailTiles ? (
        <Grid
          id={`service-category-${props.id}-thumbnail-container`}
          container
          spacing={3}
          className={classes.servicesContainer}
        >
          <ResumeBuilderTile categoryId={props.id} />
          <PersonalityQuizTile categoryId={props.id} />
          {props.services.map((service) => (
            <Grid item sm={6} md={6} key={service.id}>
              <ThumbnailCard
                handleLaunchClick={() => {
                  props.handleLaunchClick(service);
                }}
                {...service}
                isRelativePath={props.isRelativePath(service.serviceUrl)}
              />
            </Grid>
          ))}
        </Grid>
      ) : showCarousel ? (
        <Grid id={`service-category-${props.id}-carousel-container`} container sm={12} className={classes.servicesContainer}>
          <ServiceCarousel
            categoryId={props.id}
            onCardClick={(service) => {
              props.handleLaunchClick(service);
            }}
            services={props.services}
          />
        </Grid>
      ) : showIconTiles ? (
        <Grid
          id={`service-category-${props.id}-icon-card-container`}
          container
          spacing={3}
          className={classes.servicesContainer}
        >
          {props.services.map((service) => (
            <Grid item sm={6} md={6} key={service.id}>
              {service.isAPDSPodcast ? (
                <APDSPodcast
                  handleLaunchClick={() => {
                    props.handleLaunchClick(service);
                  }}
                  {...service}
                  isRelativePath={props.isRelativePath(service.serviceUrl)}
                  trackingType={Constant.TRACKING_TYPES.SERVICE}
                  trackingId={`launch-service-${service.id}`}
                />
              ) : (
                <IconCard
                  handleLaunchClick={() => {
                    props.handleLaunchClick(service);
                  }}
                  {...service}
                  isRelativePath={props.isRelativePath(service.serviceUrl)}
                  trackingType={Constant.TRACKING_TYPES.SERVICE}
                  trackingId={`launch-service-${service.id}`}
                />
              )}
            </Grid>
          ))}
        </Grid>
      ) : showTools ? (
        <Grid id={`service-category-${props.id}-tools-container`} container className={classes.servicesContainer}>
          <Tools handleLaunchClick={props.handleLaunchClick} collection={props.services} />
        </Grid>
      ) : null}

      {props.total > props.services.length && !(showCarousel || showTools) && (
        <Box className={classes.showAllContainer}>
          <Button
            className={classes.showAllButton}
            endIcon={<ChevronRight />}
            size="large"
            onClick={() => {
              props.handleShowAllClick(props.id);
            }}
            id={`service-category-${props.id}-show-all-btn`}
            aria-label={intl.formatMessage({ id: 'showAll' })}
          >
            <FormattedMessage id="showAll" />
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default withStyles(styles)(CategoryDetails);
