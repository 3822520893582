import React, { forwardRef } from 'react';
import { Modal } from '@orijinworks/frontend-commons';
import { Box, IconButton } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import HearingDisabledRounded from '@mui/icons-material/HearingDisabledRounded';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage, useIntl } from 'react-intl';
import styles from './vrs-calling-modal.style';
import VRSErrorModal from '../vrs-error-modal/vrs-error-modal';
import useVRSCallingModalHook from './useVRSCallingModalHook';
import VrsCallInProgressModal from '../vrs-call-in-progress-modal/vrs-call-in-progress-modal';
import { connect } from 'react-redux';
const VRSCallingModal = forwardRef(({ isOpen, closeModal, contact, classes, onCallClick, voiceCall, modalRef }, ref) => {
  const intl = useIntl();
  const {
    handleOnClickVRSCall,
    vrsErrorModalRef,
    toggleVrsErrorModal,
    VrsCallInProgressModalRef,
    toggleVrsCallInProgressModal,
  } = useVRSCallingModalHook(closeModal, voiceCall);
  const handleOnClickTraditionalCall = () => {
    onCallClick(contact);
    closeModal();
  };

  const capitalize = (str) => {
    if (!str) return '';
    return str.charAt(0).toUpperCase() + str.slice(1);
  };
  return (
    <>
      <Modal
        ref={modalRef}
        open={isOpen}
        onClose={closeModal}
        heading={
          <Typography variant="h3" style={{ textTransform: 'none' }}>
            <FormattedMessage id="calling" /> {capitalize(contact?.firstName)} {capitalize(contact?.lastName)}
          </Typography>
        }
        cancelBtnText={intl.formatMessage({ id: 'cancel' })}
        continueButtonProps={{ style: { display: 'none' } }}
        styles={{
          mainWrapperStyles: {
            height: 'auto',
            minWidth: '580px',
            maxWidth: '780px',
            zIndex: 9999,
          },
        }}
        showFooter
        modalType={{
          type: 'normal',
          status: 'info',
        }}
      >
        <Box>
          <Typography variant="body1" className={classes.makeYourCallLink}>
            <FormattedMessage id="vrsCallModal.tips" />
          </Typography>
          <Box onClick={handleOnClickTraditionalCall} className={classes.optionBox} padding="8px 12px" marginBottom="12px">
            <Typography variant="h6">
              <FormattedMessage id="vrsCallModal.traditionalCall" />
            </Typography>
            <IconButton>
              <KeyboardArrowRight />
            </IconButton>
          </Box>
          <Box onClick={() => handleOnClickVRSCall(contact)} className={classes.optionBox} padding="12px">
            <Box className={classes.iconWrapper}>
              <HearingDisabledRounded fontSize="large" />
            </Box>
            <Box className={classes.vrsTextWrapper}>
              <Typography variant="h6">
                <FormattedMessage id="vrsCallModal.vrsMessage" />
              </Typography>
              <Typography variant="body2">
                <FormattedMessage id="vrsCallModal.vrsMessageDescription" />
              </Typography>
            </Box>
            <IconButton>
              <KeyboardArrowRight />
            </IconButton>
          </Box>
        </Box>
      </Modal>
      <VRSErrorModal ref={vrsErrorModalRef} isOpen={false} closeModal={toggleVrsErrorModal} />
      <VrsCallInProgressModal ref={VrsCallInProgressModalRef} isOpen={false} closeModal={toggleVrsCallInProgressModal} />
    </>
  );
});

const mapStateToProps = ({ voiceCall }) => ({
  voiceCall,
});
export default connect(mapStateToProps)(withStyles(styles)(VRSCallingModal));
