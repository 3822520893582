import React from 'react';
import { Box } from '@material-ui/core';
import { BackButton } from '../../../../../shared/components';
import useProgramDetailsHook from './useProgramDetailsHook';
import { connect } from 'react-redux';
import {
  CertificateDialog,
  CertificateTile,
  ConfirmUnenrollDialog,
  FooterBanner,
  MultiLaunchModules,
  ProgramOutline,
  SingleLaunchModules,
} from '../../components';
import { isEnrolled } from '../../../../../content/programs/services/programs.service';
const ProgramDetails = ({ classes, ...rest }) => {
  const {
    programId,
    details,
    modules,
    rootModule,
    dataLoading,
    openDialog,
    showCertificate,
    handleEnroll,
    handleUnenroll,
    hasContent,
    isSingleLaunchProgram,
    showConfirmationDailog,
    closeConfirmationDialog,
    goBack,
    showCertificateDialog,
    closeCertificateDialog,
  } = useProgramDetailsHook(rest);
  const isProgramEnrolled = isEnrolled(details.enrollmentStatus);

  return (
    <Box>
      <BackButton onClick={goBack} />

      <ConfirmUnenrollDialog
        programId={programId}
        open={openDialog}
        onClose={closeConfirmationDialog}
        onConfirm={handleUnenroll}
      />

      <CertificateDialog open={showCertificate} onClose={closeCertificateDialog} certificateUrl={details.certificateUrl} />

      {!dataLoading && (
        <>
          <ProgramOutline
            programId={programId}
            thumbnailUri={details.thumbnailUri}
            name={details.name}
            description={details.description}
            showActionButton={hasContent()}
            isEnrolled={isProgramEnrolled}
            onEnroll={handleEnroll}
            onUnenroll={showConfirmationDailog}
          />

          {details.certificateUrl && <CertificateTile onViewCertificate={showCertificateDialog} />}

          {isSingleLaunchProgram() ? (
            <SingleLaunchModules
              modules={modules}
              rootModule={rootModule}
              templateType={details.templateType}
              isEnrolled={isProgramEnrolled}
            />
          ) : (
            <MultiLaunchModules modules={modules} isEnrolled={isProgramEnrolled} />
          )}

          {!isProgramEnrolled && <FooterBanner programId={programId} onEnroll={handleEnroll} />}
        </>
      )}
    </Box>
  );
};

const mapStateToProps = ({ locale, app }) => ({
  selectedLocale: locale.locale,
});

export default connect(mapStateToProps)(ProgramDetails);
