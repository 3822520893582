const useCallingModalContentNoteHook = () => {
  const getMessageId = (callAttributes) => {
    if (!callAttributes) return 'default.message';

    const key = [
      callAttributes.isRecordingEnabled ? 'R' : '',
      callAttributes.isVoiceInsightsEnabled ? 'V' : '',
      callAttributes.isTranscriptionEnabled ? 'T' : '',
    ].join('');

    switch (key) {
      case 'RVT':
        return 'recordingVoiceInsightsAndTranscriptionEnabled.message';
      case 'RT':
        return 'recordingAndTranscriptionEnabled.message';
      case 'RV':
        return 'recordingAndVoiceInsightsEnabled.message';
      case 'R':
        return 'isRecordingEnabled.message';
      case 'V':
        return 'voiceInsightsEnabled.message';
      default:
        return '';
    }
  };

  return {
    getMessageId,
  };
};

export default useCallingModalContentNoteHook;
