import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { getCareerClusterQuestions } from '../../services/personality-quiz.service';

const useCareerClusterExplorationStepHook = (selectedLocale, riasecCode) => {
  const history = useHistory();
  const [questionData, setQuestionData] = useState([]);

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const response = await getCareerClusterQuestions(riasecCode.charAt(0));
        setQuestionData(response);
      } catch (err) {
        console.error('Error fetching career cluster questions:', err);
      }
    };

    if (riasecCode) {
      fetchQuestions();
    }
  }, [selectedLocale, riasecCode]);

  const handleNext = useCallback(
    (selectedCluster) => {
      history.push(`/personality-quiz/recommendations`, { selectedCluster });
    },
    [history]
  );

  return {
    questionData,
    handleNext,
  };
};

export default useCareerClusterExplorationStepHook;
