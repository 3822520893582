import { useCallback, useEffect, useState } from 'react';
import {
  fetchAllSchedules,
  prepareSchedule,
  refineSchedule,
  fetchAllGroupSchedules,
  refineGroupSchedule,
} from './schedule.service';
import { Constant } from '../../services';
const useScheduleWrapperHook = (featureName) => {
  const [schedule, setSchedule] = useState([]);
  const [isClosed, setIsClosed] = useState(false);
  const [isGroupScheduleClosed, setIsGroupScheduleClosed] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [groupSchedule, setGroupSchedule] = useState([]);
  const [pollingConfig, setPollingConfig] = useState({
    featureName: '',
    startPolling: true,
  });

  const checkIfActiveSlotIsClosed = (data) => {
    let closed = false;
    const activeSlotItem = data.find((item) => !!item.activeSlot);
    if (activeSlotItem) {
      closed = activeSlotItem.activeSlot.isClosed;
    }
    return closed;
  };

  const retrieveScheduleData = useCallback(
    async ({ showLoader }) => {
      try {
        const allSchedules = await fetchAllSchedules({ showLoader });
        const scheduleData = allSchedules.find((item) => item.featureName === featureName);
        if (scheduleData) {
          const refinedSchedule = refineSchedule(scheduleData.schedules);
          const preparedSchedule = prepareSchedule(refinedSchedule);
          setSchedule(preparedSchedule);
          setIsClosed(checkIfActiveSlotIsClosed(preparedSchedule));
        } else {
          // if there is no schedule data for the feature, reset the schedule and isClosed state
          setSchedule([]);
          setIsClosed(false);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [featureName]
  );

  const retrieveGroupScheduleData = useCallback(
    async ({ showLoader }) => {
      try {
        const allGroupSchedules = await fetchAllGroupSchedules({ showLoader });
        const groupScheduleData = allGroupSchedules.find((item) => item.featureName === featureName);
        if (groupScheduleData) {
          const refinedSchedule = refineGroupSchedule(groupScheduleData.schedules);
          const preparedSchedule = prepareSchedule(refinedSchedule);
          setGroupSchedule(preparedSchedule);
          setIsGroupScheduleClosed(checkIfActiveSlotIsClosed(preparedSchedule));
        } else {
          setGroupSchedule([]);
          setIsGroupScheduleClosed(true);
        }
      } catch (e) {
        console.log(e);
      } finally {
        setIsLoading(false);
      }
    },
    [featureName]
  );

  useEffect(() => {
    retrieveGroupScheduleData({ showLoader: true });
  }, [retrieveGroupScheduleData]);

  useEffect(() => {
    const pollingInterval = setInterval(() => {
      retrieveGroupScheduleData({ showLoader: false });
    }, Constant.VOICE_CALL_SCHEDULE_POLLING_INTERVAL);

    return () => clearInterval(pollingInterval);
  }, [retrieveGroupScheduleData, featureName]);

  useEffect(() => {
    retrieveScheduleData({ showLoader: true });
  }, [retrieveScheduleData]);

  useEffect(() => {
    const pollingInterval = setInterval(
      () => {
        retrieveScheduleData({ showLoader: false });
      },
      Constant.FEATURES_ENUM.VOICE_CALLS_SCHEDULE === featureName
        ? Constant.VOICE_CALL_SCHEDULE_POLLING_INTERVAL
        : Constant.POLL_CONFIG.APPLICATION_WIDE_POLL_INTERVAL
    );

    if (!pollingConfig.startPolling) clearInterval(pollingInterval);

    return () => {
      clearInterval(pollingInterval);
    };
  }, [retrieveScheduleData, pollingConfig]);

  const getSchedule = () => {
    const noOfItemsPerSet = Math.ceil(schedule.length / 2);
    const firstColumnSet = schedule.slice(0, noOfItemsPerSet);
    const secondColumnSet = schedule.slice(noOfItemsPerSet, schedule.length);
    return [firstColumnSet, secondColumnSet];
  };

  const handlePollingInterval = (featureName, startPolling) => {
    setPollingConfig({ featureName, startPolling });
  };

  return {
    isLoading,
    isClosed,
    getSchedule,
    handlePollingInterval,
    schedule,
    groupSchedule,
    isGroupScheduleClosed,
  };
};

export default useScheduleWrapperHook;
