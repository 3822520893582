import { useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { Constant } from '../../../../../shared';

const useCareerInterestSelectionStepHook = () => {
  const history = useHistory();
  const handleExploreInterestsClick = useCallback(() => {
    history.push('/personality-quiz/explore-interests');
  }, [history]);

  const handleCareerPathClick = useCallback(() => {
    history.push('/personality-quiz/career-in-mind');
  }, [history]);

  const handleFoundationClick = useCallback(() => {
    history.push('/programs', { filter: Constant.Tag.RESOURCE_PROVIDER_ESSENTIAL_ED });
  }, [history]);

  return {
    onExploreInterestsClick: handleExploreInterestsClick,
    onCareerPathClick: handleCareerPathClick,
    onFoundationClick: handleFoundationClick,
  };
};

export default useCareerInterestSelectionStepHook;
