import { makeStyles } from '@material-ui/core';

export default makeStyles((theme) => ({
  container: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.white,
    borderRadius: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    alignSelf: 'stretch',
    flexDirection: 'row',
    gap: theme.spacing(2),
  },
  root: {
    padding: theme.spacing(2),
  },
  mainTitle: {
    marginBottom: theme.spacing(4),
    color: theme.palette.greyScale[110],
    fontWeight: '800',
    textTransform: 'none',
    lineHeight: 'normal',
  },
  description: {
    marginBottom: theme.spacing(4),
    color: theme.palette.greyScale[110],
    width: '100%',
  },
  button: {
    borderRadius: 100,
    textTransform: 'none',
    border: ({ selectedCareerCluster }) => (selectedCareerCluster ? `1px solid ${theme.palette.accentTeal.main}` : 'none'),
    backgroundColor: ({ selectedCareerCluster }) =>
      selectedCareerCluster ? theme.palette.accentTeal.main : theme.palette.primary.light,
    color: ({ selectedCareerCluster }) =>
      selectedCareerCluster ? theme.palette.common.white : theme.palette.accentTeal.main,
    '&:hover': {
      backgroundColor: ({ selectedCareerCluster }) =>
        selectedCareerCluster ? theme.palette.accentTeal.dark : 'transparent',
      border: ({ selectedCareerCluster }) => (selectedCareerCluster ? `2px solid ${theme.palette.accentTeal.dark}` : 'none'),
    },
  },
}));
