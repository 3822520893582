import { Box, IconButton, Modal, withStyles } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import CallEndIcon from '@material-ui/icons/CallEnd';
import React from 'react';
import styles from './calling-modal.style';
import useCallingModalHook from './useCallingModalHook';
import MicIcon from '@material-ui/icons/Mic';
import MicOffIcon from '@material-ui/icons/MicOff';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import { ContactRequestConstants } from '../../services';
import { Timer } from '../../../../shared';
import { useIntl } from 'react-intl';
import CallingModalContentNote from '../calling-modal-content-note/calling-modal-content-note';
const CallingModal = (props) => {
  const { classes } = props;
  const meetingManager = useMeetingManager();
  const { onEndCallClick, onMuteUnmuteCall, isMute } = useCallingModalHook(props, meetingManager);
  const intl = useIntl();
  return (
    <>
      <Modal
        open={props.isCallingModalOpen}
        onClose={() => {}}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className={classes.main}>
          <AccountCircleIcon className={classes.AccountCircleIcon} />
          <Typography id="contact-name" variant="h1" className={classes.description}>
            {props?.selectedContact?.firstName + ' ' + props?.selectedContact?.lastName}
          </Typography>
          <Typography id="calling-status" variant="body1" className={classes.status}>
            {props?.callStatus}
          </Typography>
          {props?.callStatus === ContactRequestConstants.CONTACT_CONNECTION_STATUSES.CONNECTED ? (
            <Timer initialElapsedTime={props.timePerCall} isTimerFlagEnabled={props.isCallinProgress} />
          ) : null}
          <Box className={classes.innerMain}>
            <Box className={classes.CallEndIconBox}>
              <IconButton
                className={classes.CallEndIconBtn}
                size="large"
                id="end-call"
                onClick={onEndCallClick}
                aria-label={intl.formatMessage({ id: 'endCall' })}
              >
                <CallEndIcon className={classes.CallEndIcon} />
              </IconButton>
            </Box>
            <Box className={classes.MuiIconBox}>
              <IconButton
                id="mute-call"
                className={classes.MicIconBtn}
                onClick={onMuteUnmuteCall}
                aria-label={intl.formatMessage({ id: isMute ? 'unmute' : 'mute' })}
              >
                {isMute ? <MicOffIcon className={classes.MicOnOffIcon} /> : <MicIcon className={classes.MicOnOffIcon} />}
              </IconButton>
            </Box>
          </Box>
          <CallingModalContentNote callAttributes={props.callAttributes} />
        </Box>
      </Modal>
    </>
  );
};

export default withStyles(styles)(CallingModal);
