export default (theme) => ({
  optionBox: {
    textAlign: 'center',
  },
  imagePlaceholder: {
    width: '100%',
    height: '200px',
    marginBottom: 16,
    objectFit: 'contain',
    [theme.breakpoints.between('sm', 'md')]: {
      height: '250px',
      marginBottom: 20,
    },
    [theme.breakpoints.up('md')]: {
      height: '300px',
      marginBottom: 24,
    },
    [theme.breakpoints.up('lg')]: {
      height: '350px',
    },
    [theme.breakpoints.up('xl')]: {
      height: '400px',
    },
  },
  button: {
    borderRadius: '100px',
    textTransform: 'none',
    width: '280px',
    [theme.breakpoints.up('md')]: {
      width: '350px',
    },
  },
});
