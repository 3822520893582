import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Button, Typography } from '@orijinworks/frontend-commons';
import styles from './career-interest-quiz-cta.style';
import { FeatureControl } from '../../../../v2/core';
import { Constant } from '../../../../shared';
import withUnleash from '../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import { PENDO_TRACK_EVENTS_ENUM, trackPendoEvent } from '../../../../core';
import { FormattedMessage } from 'react-intl';

/**
 * Component to display Career Interest Quiz.
 * It is wrapped with FeatureControl to check if the feature is enabled or not.
 *
 * @component
 * @param {object} props - Component props
 * @returns {React.Component}
 */
const CareerQuiz = ({ classes, unleashProps }) => {
  const CareerInterestQuiz = unleashProps.isFlagEnabled();

  useEffect(() => {
    if (CareerInterestQuiz) {
      trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.RECOMMENDATION_QUIZ_DISPLAYED, {
        source: 'homepage',
      });
    }
  }, [CareerInterestQuiz]);

  const bannerUrl = `${process.env.REACT_APP_CDN_BASE_URL}/assets/img/personality-quiz-banner.png`;

  const containerSx = {
    backgroundImage: `linear-gradient(0deg, rgba(255, 255, 255, 0.9), rgba(255, 255, 255, 0.9)), url('${bannerUrl}')`,
    backgroundRepeat: 'no-repeat',
  };

  const handleAttemptQuizClick = () => {
    trackPendoEvent(PENDO_TRACK_EVENTS_ENUM.RECOMMENDATION_QUIZ_STARTED, {
      source: 'homepage',
    });
  };

  return (
    <FeatureControl featureName={Constant.FEATURES_ENUM.PERSONALITY_QUIZ}>
      {CareerInterestQuiz && (
        <Box className={classes.root}>
          <Box className={classes.container} sx={containerSx}>
            <Typography variant="h3" className={classes.title} data-testid="career-interest-quiz-cta-title">
              <FormattedMessage id="careerInterestQuizCTATitle" />
            </Typography>
            <Box className={classes.contentWrapper}>
              <Typography variant="body1" className={classes.description} data-testid="career-interest-quiz-cta-description">
                <FormattedMessage id="careerInterestQuizCTADescription" />
              </Typography>
              <Link data-testid="career-interest-quiz-cta" to={'/personality-quiz'}>
                <Button
                  variant="contained"
                  color="secondary"
                  data-testid="career-interest-quiz-cta-button"
                  className={classes.button}
                  onClick={handleAttemptQuizClick}
                >
                  <FormattedMessage id="careerInterestQuizCTAButton" />
                </Button>
              </Link>
            </Box>
          </Box>
        </Box>
      )}
    </FeatureControl>
  );
};

CareerQuiz.propTypes = {
  /**
   * The classes object to use for styling that will be injected by withStyles.
   */
  classes: PropTypes.object.isRequired,
  /**
   * The unleash props injected by withUnleash HOC.
   */
  unleashProps: PropTypes.object.isRequired,
};

export default withUnleash(withStyles(styles)(CareerQuiz), UnleashService.FLAGS.ENABLE_CAREER_INTEREST_QUIZ);
