import * as store from './';

export const isEncoderRegistered = (isRegistered) => {
  return {
    type: store.IS_ENCODER_REGISTERED,
    isRegistered,
  };
};

export const setCallInProgress = (meetingInfo) => {
  return {
    type: store.CALL_IN_PROGRESS,
    meetingInfo,
  };
};
