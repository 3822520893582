const styles = (theme) => ({
  makeYourCallLink: {
    marginBottom: '20px !important',
    color: '#0078A2',
    fontWeight: '700 !important',
    fontSize: '16px !important',
    fontFamily: 'Inter ',
    letterSpacing: '2% ',
  },
  optionBox: {
    border: `1px solid ${theme.palette.grey[300]}`,
    borderRadius: '12px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  iconWrapper: {
    backgroundColor: 'var(--green-pale)',
    color: 'var(--navy-main)',
    borderRadius: '50%',
    padding: '10px',
    marginRight: '18px',
    display: 'flex',
    alignItems: 'center',
  },
  vrsTextWrapper: {
    flex: 1,
    marginRight: theme.spacing(2),
  },
});

export default styles;
