import React from 'react';
import PropTypes from 'prop-types';
import { Box, CardContent, CardMedia, Grid, Icon, Chip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';
import styles from './program.style';
import { Constant } from '../../../../../shared/services/constant/constant';
import { LinkButton, Typography } from '@orijinworks/frontend-commons';

const Program = (props) => {
  const {
    classes,
    programId,
    title,
    description,
    image,
    altText,
    enrolled,
    onClick,
    providerLogo,
    providerLogoAltText,
    category,
    tag,
  } = props;

  return (
    <LinkButton
      id={`program-${programId}-container`}
      onClick={onClick}
      className={[classes.root, classes.progCard].join(' ')}
      tracking-type={Constant.TRACKING_TYPES.PROGRAM}
      tracking-id={`view-program-details-${programId}`}
      aria-label={`View ${title}`}
      variant="text"
    >
      <Grid container>
        <Grid item sm={2} md={2} className={classes.thumbnailContainer}>
          <CardMedia
            id={`program-${programId}-thumbnail`}
            component="img"
            alt=""
            className={classes.thumbnail}
            image={image}
            title={altText || title}
          />
        </Grid>
        <Grid item sm={10} md={10} className={classes.content}>
          <CardContent className={classes.cardContent}>
            {providerLogo && (
              <Box display="flex" alignItems="center" className={classes.logoContainer}>
                <CardMedia
                  id={`program-${programId}-provider-logo`}
                  component="img"
                  alt={providerLogoAltText}
                  className={classes.providerLogo}
                  image={providerLogo}
                />
                <Typography variant="overlay" className={classes.logoText}>
                  {providerLogoAltText}
                </Typography>
              </Box>
            )}
            <Typography variant="h4" id={`program-${programId}`} gutterBottom className={classes.cardTitle}>
              {title}
            </Typography>

            {description && (
              <Typography variant="body1" className={classes.description} color="var(--grey-90)" component="p">
                {description}
              </Typography>
            )}

            {category && (
              <Chip className={[classes.categoryChip, classes[category.backgroundClass]].join(' ')} label={category.name} />
            )}
          </CardContent>
          <Typography variant="overlay" className={classes.tagText}>
            {tag}
          </Typography>
        </Grid>
      </Grid>

      {enrolled && (
        <Box className={classes.banner} id={`program-${programId}-enrolled-program-badge`}>
          <Icon>task_alt</Icon>
          <Typography component="p" className={classes.bannerText}>
            <FormattedMessage id="enrolled" />
          </Typography>
        </Box>
      )}
    </LinkButton>
  );
};

Program.propTypes = {
  programId: PropTypes.number.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  altText: PropTypes.string,
  buttonTitle: PropTypes.element.isRequired,
  enrolled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
  providerLogo: PropTypes.string,
  providerLogoAltText: PropTypes.string,
  category: PropTypes.object,
  tag: PropTypes.string,
};

export default withStyles(styles)(Program);
