const styles = (theme) => ({
  headingText: {
    color: theme.palette.navy.main,
  },
  bodyText: {
    color: theme.palette.greyScale[110],
    marginTop: '10px !important',
  },
});

export default styles;
