import React, { useEffect } from 'react';
import { withAuth0 } from '@auth0/auth0-react';
import { Loader } from '@orijinworks/frontend-commons';
import { withKeycloak } from '@react-keycloak/web';
import { handleLogout } from '../utils';

const Logout = ({ auth0, keycloak }) => {
  useEffect(() => {
    handleLogout(keycloak, auth0);
  }, [auth0, keycloak]);

  return <Loader isLoading />;
};

export default withAuth0(withKeycloak(Logout));
