import { ContactRequestConstants, endVRSCall } from '../../services';

const useVRSCallInProgressModalHook = () => {
  const endCall = async () => {
    const meetingId = localStorage.getItem('meetingId');
    try {
      await endVRSCall(meetingId, ContactRequestConstants.VRS_SOURCE.RESIDENT_VIA_LP);
      localStorage.removeItem('meetingId');
      localStorage.removeItem('intentUrl');
    } catch (e) {
      console.error('Error in ending VRS Call', e);
    }
  };

  const redirectBackToApp = () => {
    const intentUrl = localStorage.getItem('intentUrl');
    if (intentUrl) {
      window.location.href = intentUrl;
    }
  };

  return {
    endCall,
    redirectBackToApp,
  };
};

export default useVRSCallInProgressModalHook;
