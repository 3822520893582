import * as store from './';

const initialState = {
  isRegistered: false,
  meetingInfo: null,
};

export const voiceCallReducer = (state = initialState, action) => {
  switch (action.type) {
    case store.IS_ENCODER_REGISTERED:
      return {
        ...state,
        isRegistered: action.isRegistered,
      };
    case store.CALL_IN_PROGRESS:
      return {
        ...state,
        meetingInfo: action.meetingInfo,
      };
    default:
      return state;
  }
};
