import React from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Divider, Grid, withStyles, Button } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import { connect } from 'react-redux';
import Program from '../../../programs/containers/program/program';
import styles from './recommendations.styles';
import { Constant } from '../../../../../shared/services';
import GenericAction from '../interest-profiler-action/generic-action';
import useRecommendationsHook from './useRecommendationsHook';
import FeatureNavigation from '../feature-navigation/feature-navigation';
import { getCategoryFromName } from '../../../../shared/services/utility/utility';
import NoRecommendationsFound from './no-recommendations-found/no-recommendations-found';
import RecommendationDescription from './recommendation-description/recommendation-description';

const Recommendations = ({ classes, selectedLocale }) => {
  const intl = useIntl();
  const location = useLocation();
  const selectedCluster = location.state?.selectedCluster;

  const {
    recommendation,
    recommendationType,
    getTags,
    navigateToAllContent,
    handleLearnMore,
    handleExploreClick,
    handleProgramClick,
  } = useRecommendationsHook(selectedLocale, selectedCluster, classes);

  const hasPrograms = recommendation && recommendation.length > 0;

  return (
    <>
      <FeatureNavigation />
      <Box
        data-testid="recommendations-container"
        aria-label={intl.formatMessage({ id: 'personality-quiz.level4.title' })}
        data-tracking-id="recommendations"
      >
        <Box className={classes.root}>
          <Typography marginBottom="32px" variant="h1" data-testid="main-title" className={classes.mainTitle}>
            <FormattedMessage
              id={
                hasPrograms ? 'personality-quiz.level4.title' : 'personality-quiz.level4.recommendations.not.found.heading'
              }
            />
          </Typography>
          {hasPrograms && (
            <>
              <Typography
                marginBottom="56px"
                variant="body1"
                className={classes.description}
                data-testid="exploration-description"
              >
                <FormattedMessage id="personality-quiz.level4.description" />
              </Typography>

              <Typography
                marginBottom="30px"
                component="h2"
                variant="h2"
                data-testid="main-title"
                className={classes.mainTitle}
              >
                <FormattedMessage id="personality-quiz.level4.topRecommendation" />
              </Typography>

              {recommendation.map((item, index) => (
                <Grid item sm={12} md={12} key={index}>
                  <Box marginBottom="24px">
                    <Program
                      title={item.title || 'title '}
                      description={item.description || 'description'}
                      image={item.thumbnail}
                      altText={item.thumbnailAltText || 'Alt text'}
                      buttonTitle={intl.formatMessage({ id: 'viewProgram' })}
                      enrolled={item.enrollmentState === 'ENROLLED'}
                      onClick={(e) => e.stopPropagation()}
                      programId={item.bundleId || 'program id'}
                      providerLogo={item.resourceProvider?.thumbnailUri}
                      providerLogoAltText={item.resourceProvider?.contentProvider || 'resource provider'}
                      category={
                        item.category && item.type === Constant.RECOMMENDATION_TYPES.PROGRAM
                          ? getCategoryFromName(item.category.name)
                          : null
                      }
                      tag={getTags(item.tags)}
                    />
                  </Box>
                  <Button variant="contained" className={classes.learnMoreButton} onClick={() => handleLearnMore(item)}>
                    <FormattedMessage id="learnMore" />
                  </Button>
                  <RecommendationDescription item={item} classes={classes} />
                </Grid>
              ))}

              <Box py={4}>
                <Divider />
              </Box>
              <GenericAction
                title={
                  recommendationType === Constant.RECOMMENDATION_TYPES.PROGRAM
                    ? 'personality-quiz.level4.recommendationFooterHeading'
                    : 'personality-quiz.level4.recommendationFooterExploreHeader'
                }
                buttonText={
                  recommendationType === Constant.RECOMMENDATION_TYPES.PROGRAM
                    ? 'personality-quiz.level4.recommendationFooterButton'
                    : 'personality-quiz.level4.recommendationFooterExploreButton'
                }
                onClick={() => navigateToAllContent()}
              />
            </>
          )}
          {!hasPrograms && (
            <>
              <NoRecommendationsFound
                desc="personality-quiz.level4.recommendations.not.found.view.all.programs.desc"
                buttonText="personality-quiz.level4.recommendations.not.found.view.all.programs.buttonText"
                onClick={handleProgramClick}
              />
              <Box py={4}>
                <Divider />
              </Box>
              <NoRecommendationsFound
                desc="personality-quiz.level4.recommendations.not.found.check.explore.services.desc"
                buttonText="personality-quiz.level4.recommendations.not.found.check.explore.services.buttonText"
                onClick={handleExploreClick}
              />
            </>
          )}
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedLocale: state.locale.locale,
});

export default connect(mapStateToProps)(withStyles(styles)(Recommendations));
