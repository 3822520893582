import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box, Typography, Button } from '@material-ui/core';
import useStyles from './generic-action.styles';
import PropTypes from 'prop-types';

const GenericAction = ({ title, desc, buttonText, onClick, interestProfileArea }) => {
  const classes = useStyles({ interestProfileArea });

  const renderTitle = () => {
    return interestProfileArea ? (
      <FormattedMessage id="interest-profiler-action.title.completed" />
    ) : (
      <FormattedMessage id={title} />
    );
  };

  const renderDesc = () => {
    return interestProfileArea ? (
      <>
        <FormattedMessage id="interest-profiler-action.description.completed" />
        <Typography component="span" className={classes.interestProfiler} data-testid="interest-profiler-text">
          {interestProfileArea}
        </Typography>
      </>
    ) : (
      <FormattedMessage id={desc} />
    );
  };

  const renderButtonText = () => {
    return interestProfileArea ? (
      <FormattedMessage id="interest-profiler-action.button.completed" />
    ) : (
      <FormattedMessage id={buttonText} />
    );
  };

  return (
    <Box className={classes.container} data-testid="generic-action">
      <Typography variant="body1" className={classes.title} data-testid="action-title">
        {renderTitle()}
      </Typography>

      {(desc || interestProfileArea) && (
        <Typography variant="body1" className={classes.description} data-testid="action-description">
          {renderDesc()}
        </Typography>
      )}
      <Button
        variant="outlined"
        color="primary"
        className={classes.button}
        onClick={onClick || (() => {})}
        data-testid="action-button"
      >
        {renderButtonText()}
      </Button>
    </Box>
  );
};

GenericAction.propTypes = {
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  answersString: PropTypes.string,
};

export default GenericAction;
