import React from 'react';
import {
  withStyles,
  Box,
  Typography,
  MenuItem,
  FormHelperText,
  FormControl,
  Select,
  ListItemText,
  Checkbox,
  Grid,
  Button,
} from '@material-ui/core';
import { FormattedMessage } from 'react-intl';

import styles from './personality.style';
import { Constant } from '../../../../shared/services';

const MenuProps = {
  variant: 'menu',
  getContentAnchorEl: null,
};

const Personality = (props) => {
  const { classes, questions, mode, handleAnswerChange } = props;

  const getAnswerText = (answer, options) => {
    if (Array.isArray(answer) && answer.length > 0) {
      const selectedValues = answer.map((value) => options.find((item) => item.id === value).displayValue).join(', ');
      return selectedValues;
    } else if (Array.isArray(answer) && answer.length === 0) {
      return 'N/A';
    }
    const selectedOption = options.find((option) => option.id === answer);
    return selectedOption ? selectedOption.displayValue : 'N/A';
  };

  const getSelectedValues = (selected, all) => {
    all = [...all, { displayValue: 'Please Select', id: '-1' }];
    const selectedValues = selected.map((value) => all.find((item) => item.id === value).displayValue).join(', ');
    return selectedValues;
  };

  const handleMultiSelect = (questionId, value, maxSelection) => {
    if (value.length > 0) {
      value = value.filter((item) => item !== '-1');
      handleAnswerChange(questionId, value, maxSelection);
    } else {
      handleAnswerChange(questionId, [], maxSelection);
    }
  };

  return (
    <Box className={classes.root}>
      <Box id="personality-questions-container" className={classes.content}>
        <Typography id="personality-title" color="primary" className={classes.title}>
          <FormattedMessage id="personalityQuestions" />
        </Typography>

        {questions.map((question, index) => (
          <FormControl key={question.id} fullWidth variant="outlined" className={index > 0 ? classes.gutterTop : ''}>
            <Typography
              id={question.question}
              className={classes.questionTitle}
              gutterBottom={!question.helpText && mode === 'edit'}
            >
              {`${index + 1}. ` + question.question}
            </Typography>
            {mode === 'view' ? (
              <Typography variant="h4" className={classes.disabled}>
                {getAnswerText(question.answer, question.answerOptions)}
              </Typography>
            ) : (
              <>
                {question.helpText && <FormHelperText className={classes.helperText}>{question.helpText}</FormHelperText>}
                {question.questionType === Constant.MY_PROFILE_QUESTION_TYPES.DROPDOWN ? (
                  question.maxSelection > 1 ? (
                    <Select
                      value={question.answer && question.answer.length > 0 ? question.answer : ['-1']}
                      onChange={(e) => {
                        handleMultiSelect(question.id, e.target.value, question.maxSelection);
                      }}
                      multiple
                      renderValue={(selected) => getSelectedValues(selected, question.answerOptions)}
                      MenuProps={MenuProps}
                      inputProps={{ 'aria-labelledby': question.question }}
                    >
                      <MenuItem value="-1" disabled>
                        Please Select
                      </MenuItem>
                      {question.answerOptions.map((answerOption) => (
                        <MenuItem key={answerOption.id} value={answerOption.id}>
                          <Checkbox
                            color="primary"
                            checked={question.answer ? question.answer.indexOf(answerOption.id) > -1 : false}
                          />
                          <ListItemText primary={answerOption.displayValue} />
                        </MenuItem>
                      ))}
                    </Select>
                  ) : (
                    <Select
                      value={question.answer || '-1'}
                      onChange={(e) => {
                        handleAnswerChange(question.id, e.target.value, question.maxSelection);
                      }}
                      inputProps={{ 'aria-labelledby': question.question }}
                    >
                      <MenuItem value="-1" disabled>
                        Please Select
                      </MenuItem>
                      {question.answerOptions.map((answerOption) => (
                        <MenuItem key={answerOption.id} value={answerOption.id}>
                          {answerOption.displayValue}
                        </MenuItem>
                      ))}
                    </Select>
                  )
                ) : (
                  question.questionType === Constant.MY_PROFILE_QUESTION_TYPES.SCALE && (
                    <Grid container spacing={1} className={classes.scaleContainer}>
                      {question.answerOptions.map((answerOption, index) => (
                        <Grid item key={answerOption.id}>
                          <Button
                            size="large"
                            className={[
                              classes.scaleOption,
                              index === question.answer ? classes.activeScaleOption : '',
                            ].join(' ')}
                            onClick={() => {
                              handleAnswerChange(question.id, index, question.maxSelection);
                            }}
                            aria-label={index}
                          >
                            {index}
                          </Button>
                        </Grid>
                      ))}
                    </Grid>
                  )
                )}
              </>
            )}
          </FormControl>
        ))}
      </Box>
    </Box>
  );
};

export default withStyles(styles)(Personality);
