import { useFlag, useFlagsStatus, useUnleashClient, useVariant } from '@unleash/proxy-client-react';

/**
 * @name useUnleashHook
 * @description A hook for accessing Unleash feature flags and variants.
 * This hook no longer sets the Unleash context as that's now handled by UnleashContextProvider.
 *
 * @param {string} flagName - The name of the feature flag to check
 * @returns {object} - Object containing methods to access flag status and Unleash client
 */
const useUnleashHook = (flagName) => {
  const enabled = useFlag(flagName);
  const unleashClient = useUnleashClient();
  const variant = useVariant(flagName);
  const { flagsReady, flagsError } = useFlagsStatus();

  const isFlagEnabled = () => enabled;
  const getVariant = () => variant;
  const getClient = () => unleashClient;
  const getFlagsStatus = () => ({ flagsReady, flagsError });

  return {
    isFlagEnabled,
    getClient,
    getVariant,
    getFlagsStatus,
  };
};

export default useUnleashHook;
