export default (theme) => ({
  root: {
    width: '450px',
    backgroundColor: '#061E44',
    marginTop: '230px',
    display: 'flex',
  },
  icon: {
    color: '#FFFFFF',
    width: '16px',
    height: '16px',
    marginLeft: '10px',
    marginTop: '6px',
  },
  text: {
    '&.MuiTypography-body2': {
      color: '#FFFFFF',
      padding: '3px',
      marginRight: '15px',
      fontStyle: 'italic',
    },
  },
});
