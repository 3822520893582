import { Constant } from '../../../../../shared';
import { ThumbnailCard } from '../../../../shared';
import React from 'react';
import usePersonalityQuizTileHook from './usePersonalityQuizTileHook';
import { useIntl } from 'react-intl';
import { Grid } from '@material-ui/core';
import { connect } from 'react-redux';
import withUnleash from '../../../../../core/components/unleash/withUnleash';
import { UnleashService } from '../../../../../core/services/unleash/unleash';

export const PersonalityQuizTile = (props) => {
  const { showTile, launchPersonalityQuiz } = usePersonalityQuizTileHook(props);

  const intl = useIntl();

  if (!showTile()) {
    return null;
  }

  return (
    <Grid item sm={12} md={4}>
      <ThumbnailCard
        title={intl.formatMessage({ id: 'personality-quiz-tile.title' })}
        description={intl.formatMessage({ id: 'personality-quiz-tile.description' })}
        thumbnailUrl={`${Constant.CDN_BASE_URL}/assets/img/personality-quiz-banner.png`}
        trackingType={Constant.TRACKING_TYPES.SERVICE}
        trackingId={`personality-quiz-tile`}
        onClick={launchPersonalityQuiz}
      />
    </Grid>
  );
};

const mapStateToProps = ({ app }) => ({
  features: app.features,
});

export default connect(mapStateToProps)(withUnleash(PersonalityQuizTile, UnleashService.FLAGS.ENABLE_CAREER_INTEREST_QUIZ));
