import React from 'react';
import { withStyles } from '@material-ui/core';
import { Typography, ContactChip } from '@orijinworks/frontend-commons';
import ContactRelationshipIcons from '../contact-relationship-icons/contact-relationship-icons';
import { FormattedMessage } from 'react-intl';
import styles from './private-contacts-calling.style';
import { Utility } from '../../../../shared/services';

const PrivateContactsCalling = (props) => {
  const { classes } = props;

  const formatPhoneNumber = (phoneNumber) => {
    const regex = /^\+1\d{10}$/;
    if (!regex.test(phoneNumber)) {
      throw new Error('Invalid phone number format');
    }
    const countryCode = phoneNumber.substring(0, 2);
    const areaCode = phoneNumber.substring(2, 5);
    const centralOfficeCode = phoneNumber.substring(5, 8);
    const lineNumber = phoneNumber.substring(8, 12);
    return `${countryCode} (${areaCode}) ${centralOfficeCode}-${lineNumber}`;
  };

  return (
    <>
      <Typography id="private-contacts-heading" variant="h2" className={classes.approvedContactHeader}>
        <FormattedMessage id="privlieged-contacts-heading" />
      </Typography>

      {props.privateContacts?.map((contact, index) => (
        <ContactChip
          key={index}
          icon={contact?.relationshipType && <ContactRelationshipIcons relationshipType={contact.relationshipType} />}
          name={Utility.getFullName(contact.firstName, contact.lastName)}
          relationship={
            <FormattedMessage id={contact.relationshipType.toLowerCase()}>{(text) => text.toUpperCase()}</FormattedMessage>
          }
          phoneNumber={formatPhoneNumber(contact.phoneNumber)}
          isCallingEnabled={!props.isClosed} // Use the prop to disable the call button
          onCallClick={() => props.handleCallClick(contact)} // Pass the click handler
        />
      ))}
    </>
  );
};

export default withStyles(styles)(PrivateContactsCalling);
