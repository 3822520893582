import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { Http } from '../../../../../core';
import { Constant, Utility } from '../../../../../shared/services';
import { refineTags } from '../../../../shared/services/utility/utility';
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';

const useProgramListingHook = (props, programContentFilterRef) => {
  const _utilityService = new Utility();
  const [minCharForSearch] = useState(3);
  const [selectedFilter, setSelectedFilter] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const [appliedSearchTerm, setAppliedSearchTerm] = useState('');
  const [programs, setPrograms] = useState([]);
  const [programForFilters, setProgramForFilters] = useState([]);
  const [dataLoading, setDataLoading] = useState(true);
  const [searchError, setSearchError] = useState(false);
  const [showLoaderOnFilterChange, setShowLoaderOnFilterChange] = useState(false);
  const [sortOption, setSortOption] = useState('titleAZ');
  const [isFilterOpen, setFilterOpen] = useState(false);
  const [programChangeMeta, setProgramChangeMeta] = useState(Math.random());

  useEffect(() => {
    if (props.testSuite) {
      return;
    }
    const filterTag = props.location?.state?.filter;
    getProgramsList(filterTag);
  }, [props.selectedLocale, props.location?.state]);

  const getProgramsList = async (filterTag) => {
    try {
      setDataLoading(true);
      let path = `/programs/v2/list`;
      const programsList = await Http.REQUEST.get(path);
      let filteredData = programsList?.data || [];

      if (filterTag === Constant.Tag.RESOURCE_PROVIDER_ESSENTIAL_ED) {
        filteredData = filterByTag(filteredData, filterTag);
      }

      setPrograms(filteredData);
      setProgramForFilters(programsList?.data);
      setDataLoading(false);
      setShowLoaderOnFilterChange(false);
    } catch (e) {
      console.log(e);
      setDataLoading(false);
      setShowLoaderOnFilterChange(false);
    }
  };

  const filteredPrograms = useMemo(() => {
    let filtered = programs;
    if (selectedFilter === 'completed') {
      filtered = filtered.filter((program) => program.progress === 'COMPLETED');
    } else if (selectedFilter === 'enrolled') {
      filtered = filtered.filter((program) => program.enrollmentState === 'ENROLLED' && program.progress !== 'COMPLETED');
    }
    if (appliedSearchTerm) {
      const value = appliedSearchTerm.toLowerCase().trim();
      filtered = filtered.filter((program) => {
        return (
          program.bundleName.toLowerCase().includes(value) ||
          (program.category && program.category.name.toLowerCase().includes(value)) ||
          (program.tag && program.tag.some((tag) => tag.tagName.toLowerCase().includes(value)))
        );
      });
    }

    return programContentFilterRef?.current?.getFilteredData(filtered) ?? filtered;
  }, [programs, selectedFilter, appliedSearchTerm, programChangeMeta]);

  const toggleModal = () => {
    setFilterOpen(!isFilterOpen);
    handleSearch({
      target: {
        value: '',
      },
    });
  };
  const getFilters = () => {
    const { current } = programContentFilterRef;
    let transformedFilters = [];
    if (current) {
      const { filters } = current;
      transformedFilters = filters
        .map((section, parentIndex) => {
          section['filters'] = section['filters'].map((filter, childIndex) => {
            filter['parentIndex'] = parentIndex;
            filter['childIndex'] = childIndex;
            return filter;
          });
          return section;
        })
        .filter((section) => section.filters.some((filter) => filter.checked))
        .reduce((current, next) => {
          return current.concat(next.filters.filter((filter) => filter.checked));
        }, []);
    }

    return transformedFilters;
  };
  const sortedPrograms = useMemo(() => {
    let sorted = [...filteredPrograms];
    switch (sortOption) {
      case 'titleAZ':
        sorted.sort((a, b) => a.bundleName?.localeCompare(b.bundleName));
        break;
      case 'titleZA':
        sorted.sort((a, b) => b.bundleName?.localeCompare(a.bundleName));
        break;
      default:
        break;
    }
    return sorted;
  }, [filteredPrograms, sortOption]);

  const handleFilterChange = (value) => {
    setSelectedFilter(value);
    setShowLoaderOnFilterChange(true);
    setTimeout(() => setShowLoaderOnFilterChange(false), 300);
  };

  const handleSearch = (event) => {
    const value = event.target.value;
    setSearchTerm(value.trimStart());
    setSearchError(false);
    if (value === '') {
      setAppliedSearchTerm('');
    }
  };

  const onSearch = () => {
    if (searchTerm.length >= minCharForSearch) {
      programContentFilterRef.current.clearAllFilters();
      setAppliedSearchTerm(searchTerm);
    } else {
      setSearchError(true);
    }
  };

  const onKeyDown = (event) => {
    if (event.key === 'Enter') {
      onSearch();
    }
  };

  const getTags = (tags) => {
    const elements = [];

    tags.forEach((item) => {
      if (item.tagName.includes(Constant.TAGS_KEYS.DURATION)) {
        if (elements.length > 0) {
          elements.push(' | ');
        }
        elements.push(refineTags(item.tagName));
      }
      if (
        item.tagName.includes(Constant.TAGS_KEYS.CERTIFICATE) &&
        !item.tagName.includes(Constant.TAGS_KEYS.CERTIFICATE_UNAVAILABLE)
      ) {
        if (elements.length > 0) {
          elements.push(' | ');
        }
        elements.push(<WorkspacePremiumIcon className={props.classes.ribbonIcon} />);
        elements.push(refineTags(item.tagName));
      }
    });
    return elements;
  };

  const uniquePrograms = _utilityService.removeDuplicateObj(sortedPrograms);

  const goToProgramDetails = (e, bundleId) => {
    e.stopPropagation();
    props.history.push(`/programs/${bundleId}/details`);
  };

  const handleSortChange = (event) => {
    setSortOption(event.target.value);
  };

  const filterByTag = (data, filterTag) => {
    return data.filter((program) => program.tag?.some((tag) => tag.tagName === filterTag));
  };

  return {
    minCharForSearch,
    selectedFilter,
    searchTerm,
    programs: uniquePrograms,
    dataLoading,
    searchError,
    showLoaderOnFilterChange,
    filteredPrograms: sortedPrograms,
    sortOption,
    isFilterOpen,
    programForFilters,
    setFilterOpen,
    handleFilterChange,
    handleSearch,
    onSearch,
    onKeyDown,
    getTags,
    goToProgramDetails,
    handleSortChange,
    getFilters,
    toggleModal,
    setProgramChangeMeta,
  };
};

export default useProgramListingHook;
