import React from 'react';
import { Box, withStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import { Typography } from '@orijinworks/frontend-commons';
import styles from './calling-modal-content-note.style';
import { FormattedMessage } from 'react-intl';
import useCallingModalContentNoteHook from './useCallingModalContentNoteHook';

const CallingModalContentNote = ({ classes, callAttributes }) => {
  const { getMessageId } = useCallingModalContentNoteHook();
  const messageId = getMessageId(callAttributes);

  if (!callAttributes || callAttributes.isPrivileged || !messageId || messageId === 'default.message') {
    return null;
  }

  return (
    <Box className={classes.root}>
      <ErrorIcon className={classes.icon} />
      <Typography variant="body2" className={classes.text}>
        <FormattedMessage id={messageId} defaultMessage="Unknown message" />
      </Typography>
    </Box>
  );
};

export default withStyles(styles)(CallingModalContentNote);
