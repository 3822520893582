import React, { useEffect, useState } from 'react';
import { useUnleashContext } from '@unleash/proxy-client-react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Loader } from '@orijinworks/frontend-commons';
import { fetchDeviceGroup, fetchUserIp } from '../../auth/utils';
import { AUTH_CONSTANTS } from '../../auth/constants/auth-constants';

/**
 * @name UnleashContextProvider
 * @description A centralized component responsible for setting the Unleash context once.
 * This component fetches necessary data (IP address, device info) and sets the Unleash context
 * with user and device information. It prevents redundant API calls by centralizing context setting.
 *
 * @component
 * @param {Object} props - Component props
 * @param {React.ReactNode} props.children - Child components to render after context is set
 * @param {string} [props.userName] - User's name for context
 * @param {string} [props.jurisdiction] - User's jurisdiction info
 * @param {string|number} [props.userId] - User's ID
 * @returns {React.ReactElement} The rendered component
 */
const UnleashContextProvider = ({ children, userId, username, jurisdiction }) => {
  const updateContext = useUnleashContext();
  const [isContextSet, setIsContextSet] = useState(false);

  useEffect(() => {
    /**
     * Sets the Unleash context with user and device information
     *
     * @async
     * @function setUnleashContext
     * @returns {Promise<void>}
     */
    const setUnleashContext = async () => {
      const contextData = {
        userId: userId ? parseInt(userId) : undefined,
        username,
        jurisdictionCode: jurisdiction?.shortCode,
      };

      try {
        const promises = [fetchUserIp(), fetchDeviceGroup()];
        const [ipAddress, { deviceGroup, deviceName }] = await Promise.all(promises);

        await updateContext({
          ...contextData,
          ipAddress,
          deviceGroupName: deviceGroup?.name,
          deviceName: deviceName || AUTH_CONSTANTS.DEFAULT_DEVICE_NAME,
        });
      } catch (e) {
        console.error(e);
        await updateContext({
          ...contextData,
          deviceName: AUTH_CONSTANTS.DEFAULT_DEVICE_NAME,
        });
      } finally {
        setIsContextSet(true);
      }
    };

    setUnleashContext();
  }, [userId, username, jurisdiction]);

  if (!isContextSet) {
    return <Loader isLoading />;
  }

  return <>{children}</>;
};

UnleashContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
  username: PropTypes.string,
  jurisdiction: PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    shortCode: PropTypes.string,
  }),
  userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

const mapStateToProps = ({ app }) => ({
  userId: app.userDetail.userId,
  username: app.userDetail.username,
  jurisdiction: app.userDetail.jurisdiction,
});

export default connect(mapStateToProps)(UnleashContextProvider);
