import React, { forwardRef } from 'react';
import { Modal, Typography } from '@orijinworks/frontend-commons';
import { Box } from '@material-ui/core';
import { CallEnd } from '@material-ui/icons';
import { withStyles } from '@material-ui/core/styles';
import useVRSCallInProgressModalHook from './useVRSCallInProgressModalHook';
import styles from './vrs-call-in-progress-modal.style';

const VrsCallInProgressModal = forwardRef(({ isOpen, classes }, ref) => {
  const { endCall, redirectBackToApp } = useVRSCallInProgressModalHook();
  return (
    <Modal
      ref={ref}
      modalCustomClass={classes.customModalRoot}
      open={isOpen}
      onClose={endCall}
      heading=""
      showFooter={true}
      showCrossIcon={false}
      cancelBtnText="End VRS Call"
      actionBtnText="Return to Call"
      onContinue={redirectBackToApp}
      cancelButtonProps={{ style: { color: '#E32D4E', border: '1px solid #E32D4E' }, startIcon: <CallEnd /> }}
      disableBackdropClick
      disableEscapeKeyDown
      modalType={{
        type: 'normal',
        status: 'info',
        size: 'medium',
      }}
    >
      <Box>
        <Typography id="title" variant="h3" className={classes.title}>
          Video Relay Call Active
        </Typography>
        <Typography id="subtitle" variant="body1" className={classes.description}>
          Your Video Relay Service call is in progress. <br /> Please return to the P3 App to continue your conversation.
        </Typography>
      </Box>
    </Modal>
  );
});

export default withStyles(styles)(VrsCallInProgressModal);
