import { useFlag } from '@unleash/proxy-client-react';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import { useEffect } from 'react';
import { checkSessionValidity, handleLogout } from '../utils';
import { AUTH_CONSTANTS } from '../constants/auth-constants';
import { logoutFromPartnerSystems } from '../../../shared/services/partner-logout/partner-logout.service';
import { Keycloak } from '../../../../core';

const ValidateSession = () => {
  const auth0_useLocalStorage = useFlag(UnleashService.FLAGS.AUTH0_USE_LOCAL_STORAGE);
  const auth0_validateSessionOnPageRefresh = useFlag(UnleashService.FLAGS.AUTH0_VALIDATE_SESSION_ON_PAGE_LOAD);

  useEffect(() => {
    const checkSession = async () => {
      if (auth0_useLocalStorage && auth0_validateSessionOnPageRefresh) {
        try {
          await checkSessionValidity();
        } catch (e) {
          if (e.message === AUTH_CONSTANTS.AUTH_ERROR_TYPES.LOGOUT_REQUIRED) {
            await logoutFromPartnerSystems(false);
            handleLogout(Keycloak.Keycloak);
          }
        }
      }
    };

    checkSession();
  }, [auth0_useLocalStorage, auth0_validateSessionOnPageRefresh]);

  return null;
};

export default ValidateSession;
