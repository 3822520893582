import React from 'react';
import { useFlag } from '@unleash/proxy-client-react';

/**
 * @name UnleashProvider
 * @param {string} flagName - The feature flag name
 * @param {function} enabledRenderFn - The function to render when the flag is enabled
 * @param {function} disabledRenderFn - The function to render when the flag is disabled
 * @desc A component that renders a function based on the value of a feature flag from Unleash
 * @returns {JSX.Element} The rendered JSX
 */
const UnleashProvider = ({ flagName, enabledRenderFn = () => null, disabledRenderFn = () => null }) => {
  const renderedJSX = useFlag(flagName) ? enabledRenderFn() : disabledRenderFn();

  return <>{renderedJSX}</>;
};

export default UnleashProvider;
