export const LOADER = 'LOADER';

export const SET_CURRENT_USER = 'SET_CURRENT_USER';

export const SET_LOCALE = 'SET_LOCALE';

// Modal
export const SHOW_MODAL = 'SHOW_MODAL';

export const SET_BALANCE = 'SET_BALANCE';

export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';

export const SET_FEATURES = 'SET_FEATURES';

export const SET_CONTENT_AREAS = 'SET_CONTENT_AREAS';

export const TOGGLE_TOAST = 'TOGGLE_TOAST';

export const SET_FORMS_ENABLED = 'SET_FORMS_ENABLED';

export const SET_UNREAD_MESSAGES_COUNT = 'SET_UNREAD_MESSAGES_COUNT';

export const SET_UNREAD_ANNOUNCEMENT_COUNT = 'SET_UNREAD_ANNOUNCEMENT_COUNT';

export const SET_REQUIRED_FORM_EXISTANCE = 'SET_REQUIRED_FORM_EXISTANCE';

export const SET_REQUIRED_FORM_EXIST_ON_LOGIN = 'SET_REQUIRED_FORM_EXIST_ON_LOGIN';

export const SET_LEARNER_SCHEDULE = 'SET_LEARNER_SCHEDULE';

export const SET_FRIEND_FAMILY_UNREAD_COUNT = 'SET_FRIEND_FAMILY_UNREAD_COUNT';

export const SHOW_SESSION_EXPIRE_PAGE = 'SHOW_SESSION_EXPIRE_PAGE';
export const SET_APP_LEVEL_API_LOADING = 'SET_APP_LEVEL_API_LOADING';

export const SET_IP_ADDRESS = 'SET_IP_ADDRESS';
