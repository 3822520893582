import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useFlag, useFlagsStatus } from '@unleash/proxy-client-react';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import { Auth0Provider } from '@auth0/auth0-react';
import KeycloakProvider from './keycloak/keycloak-provider';
import { setUseOktaAuth0 } from '../utils';
import { Loader } from '@orijinworks/frontend-commons';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { AUTH_CONSTANTS } from '../constants/auth-constants';

/**
 * Component to switch between Auth0 and Keycloak providers
 *
 * @component
 * @param {Object} props - Component props
 * @param {node} props.children - The children components
 */
const AuthProvider = ({ children }) => {
  const { flagsReady } = useFlagsStatus();
  const useOktaAuth0 = useFlag(UnleashService.FLAGS.USE_OKTA_AUTH0);
  const auth0_useLocalStorage = useFlag(UnleashService.FLAGS.AUTH0_USE_LOCAL_STORAGE);
  const [isUtilFlagSet, setIsUtilFlagSet] = useState(false);
  const history = useHistory();

  /**
   * Effect to set the useOktaAuth0 flag
   */
  useEffect(() => {
    if (!flagsReady) {
      return;
    }

    setUseOktaAuth0(useOktaAuth0);
    setIsUtilFlagSet(true);
  }, [useOktaAuth0, flagsReady]);

  useEffect(() => {
    if (!auth0_useLocalStorage) {
      const path = history.location.pathname;
      if (!['/', '/auth-guards'].includes(path)) {
        sessionStorage.setItem(AUTH_CONSTANTS.LAST_VISITED_PATH, path);
      }
    }
  }, [history.location.pathname, auth0_useLocalStorage]);
  /**
   * Return loader if the flags are not ready and the useOktaAuth0 flag is not set in the utils
   */
  if (!isUtilFlagSet) {
    return <Loader isLoading />;
  }

  /**
   * Return the Auth0Provider if the useOktaAuth0 flag is true.
   * Return the KeycloakProvider if the useOktaAuth0 flag is false.
   */
  if (useOktaAuth0) {
    return (
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
        useRefreshTokens
        {...(auth0_useLocalStorage && { cacheLocation: 'localstorage' })}
        authorizationParams={{
          redirect_uri: window.location.origin,
          audience: process.env.REACT_APP_AUTH0_AUDIENCE,
        }}
      >
        {children}
      </Auth0Provider>
    );
  } else {
    return <KeycloakProvider>{children}</KeycloakProvider>;
  }
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthProvider;
