export default (theme) => ({
  root: {},
  header: {
    padding: 30,
    backgroundColor: 'white',
    borderRadius: 10,
  },
  title: {
    textTransform: 'capitalize',
    fontWeight: 700,
  },
  description: {
    color: theme.palette.text.sub,
  },
  content: {
    padding: 30,
    marginTop: 30,
    backgroundColor: 'white',
    borderRadius: 10,
    position: 'relative',
  },
  divider: {
    margin: '30px 0px',
  },
  actionBox: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: 50,
  },
  actionButton: {
    width: 200,
    height: 50,
    borderRadius: 5,
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 16,
  },
  editButton: {
    width: 100,
    height: 50,
    borderRadius: 30,
    textTransform: 'capitalize',
    fontWeight: 700,
    fontSize: 16,
    position: 'absolute',
    right: 30,
  },
  gutterRight: {
    marginRight: 10,
  },
});
