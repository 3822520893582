import {
  LOADER,
  SET_CURRENT_USER,
  SET_LOCALE,
  SET_BALANCE,
  SET_TRANSACTIONS,
  SET_FEATURES,
  SHOW_MODAL,
  SET_CONTENT_AREAS,
  TOGGLE_TOAST,
  SET_FORMS_ENABLED,
  SET_UNREAD_MESSAGES_COUNT,
  SET_UNREAD_ANNOUNCEMENT_COUNT,
  SET_REQUIRED_FORM_EXISTANCE,
  SET_REQUIRED_FORM_EXIST_ON_LOGIN,
  SET_LEARNER_SCHEDULE,
  SET_APP_LEVEL_API_LOADING,
} from './';
import { SET_FRIEND_FAMILY_UNREAD_COUNT, SET_IP_ADDRESS, SHOW_SESSION_EXPIRE_PAGE } from './constants';

export function showHideLoader(loaderState) {
  return { type: LOADER, loaderState };
}

export function setLocale(locale) {
  return { type: SET_LOCALE, locale };
}

export function currentUser(currentUser) {
  return { type: SET_CURRENT_USER, currentUser };
}

export function setAppLevelApiLoading(isAppLevelApiLoading) {
  return { type: SET_APP_LEVEL_API_LOADING, isAppLevelApiLoading };
}

export function showHidePopup(modalMetaInfo) {
  return { type: SHOW_MODAL, modalMetaInfo };
}

export function setUserTokenBalance(balance) {
  return { type: SET_BALANCE, balance };
}

export function setUserTransaction(transactions) {
  return { type: SET_TRANSACTIONS, transactions };
}

export function setFeatures(features) {
  return { type: SET_FEATURES, features };
}

export function setContentAreas(collection) {
  return { type: SET_CONTENT_AREAS, collection };
}

export function toggleToast(toast) {
  return { type: TOGGLE_TOAST, toast };
}

export function setFormsEnabled(isFormsEnabled) {
  return { type: SET_FORMS_ENABLED, isFormsEnabled };
}

export function setUnreadMessagesCount(counts) {
  return { type: SET_UNREAD_MESSAGES_COUNT, counts };
}

export function setUnreadAnnouncementCount(announcementCount) {
  return { type: SET_UNREAD_ANNOUNCEMENT_COUNT, announcementCount };
}

export function setRequiredFormExistance(isRequiredFormExist) {
  return { type: SET_REQUIRED_FORM_EXISTANCE, isRequiredFormExist };
}

export function setRequiredFormExistOnLogin(isRequiredFormExistOnLogin) {
  return { type: SET_REQUIRED_FORM_EXIST_ON_LOGIN, isRequiredFormExistOnLogin };
}

export function setLearnerSchedule(schedule) {
  return { type: SET_LEARNER_SCHEDULE, schedule };
}

export function setFriendsFamilyUnreadData(unreadData) {
  return {
    type: SET_FRIEND_FAMILY_UNREAD_COUNT,
    unreadData,
  };
}

export function showSessionExpirePage(openSessionExpire) {
  return { type: SHOW_SESSION_EXPIRE_PAGE, openSessionExpire };
}

export function setIpAddress(ipAddress) {
  return { type: SET_IP_ADDRESS, ipAddress };
}
