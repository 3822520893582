import { Http } from '../../../../core';

export class FeatureContentService {
  /**
   * @name getFeatureContents
   * @desc Fetches feature contents from service to display tiles on home page
   * @return {Promise}
   */
  getFeaturedConetents = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get(`/featuredContents/`)
        .then((_successLog) => {
          const { data } = _successLog;
          resolve(data);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
