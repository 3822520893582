import React from 'react';
import { Box, withStyles } from '@material-ui/core';
import { Typography, ContactChip } from '@orijinworks/frontend-commons';
import { FormattedMessage } from 'react-intl';
import styles from './hotline-contacts.style';
import SosIcon from '@mui/icons-material/Sos';
import useContactsCallingHook from '../contacts-calling/useContactsCallingHook';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import CallingModal from '../calling-modal/calling-modal';
import StatusModal from '../status-modal/status-modal';
import { ContactRequestConstants } from '../../services';
import PropTypes from 'prop-types';

const HotlineContactsCalling = (props) => {
  const { classes, isClosed, handlePollingInterval, hotlineContacts, handleCallClick } = props;
  const meetingManager = useMeetingManager();
  const {
    isCallingModalOpen,
    selectedContact,
    transactionId,
    meetingId,
    callStatus,
    setCallStatus,
    timePerCall,
    isCallinProgress,
    setisCallingModalOpen,
    isErrorModalOpen,
    handleErroModalClose,
    handleErroModalOpen,
    formatPhoneNumber,
  } = useContactsCallingHook(meetingManager, handlePollingInterval);

  return (
    <>
      <Typography id="hotline-contacts-heading" variant="h2" className={classes.heading}>
        <FormattedMessage id="essentialContacts" />
      </Typography>
      <Typography id="hotline-contacts-subtext" variant="body1" className={classes.subtext}>
        <FormattedMessage id="essentialContacts.description" />
      </Typography>
      <Box height={32} />
      {hotlineContacts?.map((contact) => (
        <ContactChip
          key={contact.id}
          icon={<SosIcon className={classes.sosIcon} />}
          name={contact.name}
          relationship="" // If no relationship is needed, pass null or omit the prop
          phoneNumber={formatPhoneNumber(contact.phoneNumber)}
          isCallingEnabled={!isClosed} // Disable call button based on `isClosed`
          onCallClick={() => handleCallClick(contact)} // Pass the click handler
        />
      ))}

      <CallingModal
        isCallingModalOpen={isCallingModalOpen}
        setisCallingModalOpen={setisCallingModalOpen}
        setonClose={() => {}}
        selectedContact={selectedContact}
        transactionId={transactionId}
        meetingId={meetingId}
        callStatus={callStatus}
        setCallStatus={setCallStatus}
        timePerCall={timePerCall}
        isCallinProgress={isCallinProgress}
      />

      <StatusModal
        status={ContactRequestConstants.CONTACT_CONNECTION_STATUSES.FAILED}
        description={''}
        handleStatusModalOpen={handleErroModalOpen}
        handleStatusModalClose={handleErroModalClose}
        isStatusModalOpen={isErrorModalOpen}
      />
    </>
  );
};

HotlineContactsCalling.propTypes = {
  isClosed: PropTypes.bool.isRequired,
  classes: PropTypes.object.isRequired,
  handlePollingInterval: PropTypes.func.isRequired,
};
export default withStyles(styles)(HotlineContactsCalling);
