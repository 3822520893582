import { useState, useEffect } from 'react';
import { ContactRequestConstants, getAttributes, endVRSCall as endCallAPI, getMeetingDetails } from '../../services';
import { Constant } from '../../../../shared';
const useVoiceCallHook = ({ unleashProps, setMeetingInfo }) => {
  useEffect(() => {
    checkIfVoiceIDRegisterationIsRequired();
    if (unleashProps.isFlagEnabled()) {
      getActiveCallInProgress();
    }
    endVRSCallFromAndroidApp();

    return () => {
      setMeetingInfo(null); //RESETTING THE STATE SO THAT IT DOESN'T SHOW THE CALL IN PROGRESS MODAL UPON NAVIGATION
    };
  }, []);
  const TAB_VALUES = {
    Contacts: 'Contacts',
    Connections: 'Connections',
    CallHistory: 'Call History',
    VoiceIdRegistration: 'Voice ID',
  };

  const [tabActive, setTabActive] = useState(TAB_VALUES.Contacts);
  const [voiceIDRegistrationRequired, setVoiceIDRegistrationRequired] = useState(false);
  const [isA11yAttributeEnabled, setIsA11yAttributeEnabled] = useState(false);
  const [isContactRequestAllowed, setIsContactRequestAllowed] = useState(true);
  const handletabActive = (event, newTabValue) => {
    setTabActive(newTabValue);
  };

  /**
   * End's VRS call if redirectoin param from android app is present in URL
   * @return void
   */
  const endVRSCallFromAndroidApp = () => {
    const urlParams = new URLSearchParams(window.location.search);
    const isCallEnded = urlParams.get('event') === ContactRequestConstants.VRS_EVENTS.VRS_CALL_ENDED;
    const meetingId = urlParams.get('meetingId');
    if (isCallEnded && meetingId) {
      endVRSCall(meetingId, ContactRequestConstants.VRS_SOURCE.LP_VIA_ANDROID_SERVICE);
    }
  };
  /**
   * A method responsible to end the VRS call
   * @param {*} meetingId
   * @param {*} source
   */
  const endVRSCall = async (meetingId, source = ContactRequestConstants.VRS_SOURCE.RESIDENT_VIA_LP) => {
    try {
      await endCallAPI(meetingId, source);
    } catch (e) {
      console.error(e);
    }
  };
  const checkIfVoiceIDRegisterationIsRequired = async () => {
    try {
      const response = await getAttributes();
      if (response?.data) {
        const attribute = response.data.find((attribute) => attribute.attributeName === 'Voice_ID_Registration');
        if (attribute) {
          setVoiceIDRegistrationRequired(attribute.attributeValue === 'true');
        }

        const a11yAttribute = response.data.find((attribute) => attribute.attributeName === 'A11Y_VRS_ENABLED');
        if (a11yAttribute) {
          setIsA11yAttributeEnabled(a11yAttribute.attributeValue === 'true');
        }

        const contactRequestAttribute = response.data.find(
          (attribute) => attribute.attributeName === Constant.VOICE_ATTRIBUTES.CONTACT_REQUEST_ALLOWED
        );
        if (contactRequestAttribute) {
          setIsContactRequestAllowed(contactRequestAttribute.attributeValue === 'true');
        }
      }
    } catch (error) {
      console.error('Error fetching attributes:', error);
    }
  };

  const getActiveCallInProgress = async () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const isCallEnded = urlParams.get('event') === ContactRequestConstants.VRS_EVENTS.VRS_CALL_ENDED;
      if (!isCallEnded) {
        const response = await getMeetingDetails();
        setMeetingInfo(response.data);
        if (ContactRequestConstants.CALL_TYPE.VRS === response.data.callType) {
          localStorage.setItem(ContactRequestConstants.LOCAL_STORAGE_ITEM.MEETING_ID, response.data.meetingId);
          localStorage.setItem(ContactRequestConstants.LOCAL_STORAGE_ITEM.INTENT, response.data.intentURL);
        }
      }
    } catch (e) {
      console.log(e);
    }
  };

  return {
    handletabActive,
    tabActive,
    TAB_VALUES,
    voiceIDRegistrationRequired,
    checkIfVoiceIDRegisterationIsRequired,
    isA11yAttributeEnabled,
    isContactRequestAllowed,
  };
};

export default useVoiceCallHook;
