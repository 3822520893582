import React from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { Typography } from '@orijinworks/frontend-commons';
import { Constant } from '../../../../../../shared';

const RecommendationDescription = ({ item, classes }) => {
  const getRecommendationList = () => {
    const recommendationList = [
      {
        id: 'personality-quiz.level4.recommendationDescription1',
        values: { skill: item?.careerCluster?.skill?.toLowerCase() },
      },
      {
        id: 'personality-quiz.level4.recommendationDescription2',
        values: { cluster: item?.careerCluster?.title?.toLowerCase() },
      },
    ];

    if (item?.tags?.some((tag) => tag.name.includes(Constant.TAGS_KEYS.CERTIFICATE))) {
      recommendationList.push({
        id: 'personality-quiz.level4.recommendationDescription3',
        values: {},
      });
    }

    return recommendationList;
  };

  return (
    <Typography marginBottom="24px" variant="body1" className={classes.bodyText} data-testid="recommendationDescription">
      <FormattedMessage
        id={
          item?.type === Constant.RECOMMENDATION_TYPES.PROGRAM
            ? 'personality-quiz.level4.recommendationProgramDescription'
            : 'personality-quiz.level4.recommendationServiceDescription'
        }
        values={{
          program: 'course',
          childContentName: item?.childContentName,
          contentProvider: item?.resourceProvider?.contentProvider,
        }}
      />
      <ul>
        {getRecommendationList().map(({ id, values }) => (
          <li key={id}>
            <FormattedMessage id={id} values={values} />
          </li>
        ))}
      </ul>
    </Typography>
  );
};

RecommendationDescription.propTypes = {
  item: PropTypes.shape({
    type: PropTypes.string,
    careerCluster: PropTypes.shape({
      skill: PropTypes.string,
      title: PropTypes.string,
    }),
    tags: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
      })
    ),
    childContentName: PropTypes.string,
    resourceProvider: PropTypes.shape({
      contentProvider: PropTypes.string,
    }),
  }).isRequired,
  classes: PropTypes.object.isRequired,
};

export default RecommendationDescription;
