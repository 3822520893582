import React, { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Box, Typography, Divider, Button } from '@material-ui/core';
import { connect } from 'react-redux';
import useCareerInMindStepHook from './useCareerInMindStepHook';
import FeatureNavigation from '../feature-navigation/feature-navigation';
import { Autocomplete } from '@orijinworks/frontend-commons';
import useStyles from '../career-in-mind/career-in-mind-step.styles';
import GenericAction from '../interest-profiler-action/generic-action';

const CareerInMindStep = ({ selectedLocale }) => {
  const intl = useIntl();
  const [selectedCareerCluster, setSelectedCareerCluster] = useState('');
  const { careerClusterData, handleThisIsMe, handleCareerInMindClick } = useCareerInMindStepHook(
    selectedLocale,
    selectedCareerCluster
  );
  const classes = useStyles({ selectedCareerCluster });
  const handleAutocompleteChange = (event, newValue) => {
    setSelectedCareerCluster(newValue);
  };

  return (
    <>
      <FeatureNavigation />
      <Box data-testid="career-in-mind-container" data-tracking-id="career-in-mind-step">
        <Box className={classes.root}>
          <Typography component="h2" variant="h2" data-testid="main-title" className={classes.mainTitle}>
            “<FormattedMessage id="personality-quiz.level2.career.in.mind.title" />”
          </Typography>

          <Typography variant="body1" className={classes.description} data-testid="career-in-mind-description">
            <FormattedMessage id="personality-quiz.level2.career.in.mind.description" />
          </Typography>

          <Typography component="h2" variant="h2" data-testid="main-sub-desc" className={classes.mainTitle}>
            <FormattedMessage id="personality-quiz.level2.career.in.mind.sub.description" />
          </Typography>

          <Box className={classes.container}>
            <Box width="80%">
              <Autocomplete
                id="career-cluster-select"
                label={intl.formatMessage({ id: 'personality-quiz.level2.career.in.mind.select.dropdown.label' })}
                fullWidth
                freeSolo={false}
                value={selectedCareerCluster}
                onInputChange={handleAutocompleteChange}
                options={careerClusterData}
                disableClearable={true}
                testId="career-cluster-select-test"
              />
            </Box>
            <Button
              variant="outlined"
              color="primary"
              className={classes.button}
              onClick={handleThisIsMe}
              data-testid="career-in-mind-button"
              disabled={!selectedCareerCluster}
              aria-label={intl.formatMessage({ id: 'personality-quiz.level2.career.in.mind.buttonText' })}
            >
              <FormattedMessage id="personality-quiz.level2.career.in.mind.buttonText" />
            </Button>
          </Box>
          <Box py={4}>
            <Divider />
          </Box>
          <GenericAction
            title="personality-quiz.level2.career.in.mind.action.title"
            desc="personality-quiz.level2.career.in.mind.action.desc"
            buttonText="personality-quiz.level2.career.in.mind.action.explore.the.career.directory"
            onClick={handleCareerInMindClick}
          />
        </Box>
      </Box>
    </>
  );
};

const mapStateToProps = (state) => ({
  selectedLocale: state.locale.locale,
});

export default connect(mapStateToProps)(CareerInMindStep);
