import { Http } from '../../../core';

class ContactRequestConstants {
  static CONTACT_REQUEST_LIMIT = 9;
  static CALL_TYPE = {
    VRS: 'VRS',
    PSTN: 'PSTN',
  };
  static LOCAL_STORAGE_ITEM = {
    MEETING_ID: 'meetingId',
    INTENT: 'intentUrl',
  };
  static VRS_SOURCE = {
    LP_VIA_ANDROID_SERVICE: 'LP_VIA_ANDROID_SERVICE',
    RESIDENT_VIA_LP: 'RESIDENT_VIA_LP',
  };
  static VRS_EVENTS = {
    VRS_CALL_ENDED: 'vrsCallEnded',
  };
  static VOICE_ID_REGISTRATION_RECORDING_TIME_LIMIT = 30;

  /**
   * @name PRIVATE_CONTACTS_RELATIONSHIP_TYPES
   * @desc private contacts relationship types
   * @type {Object}
   */

  static get PRIVATE_CONTACTS_RELATIONSHIP_TYPES() {
    return {
      ATTORNEY: 'ATTORNEY',
      MEDICAL: 'MEDICAL',
      GENERAL_PRIVILEGED_CONTACT: 'GENERAL_PRIVILEGED_CONTACT',
    };
  }

  /**
   * @name CONTACT_CALLING_ERROR_STATUS_CODES
   * @desc contact calling error status codes
   * @type {Object}
   */
  static get CONTACT_CALLING_ERROR_STATUS_CODES() {
    return {
      DAILY_MINUTES_EXCEEDED: 'DAILY_MINUTES_EXCEEDED',
    };
  }

  static get ERROR_MESSAGES() {
    return {
      CALL_CENTER_CLOSED: 'Call center is closed',
    };
  }

  /**
   * @name CONTACT_CONNECTION_STATUSES
   * @desc contact connection statuses
   * @type {Object}
   */

  static get CONTACT_CONNECTION_STATUSES() {
    return {
      CONNECTED: 'Connected',
      CALLING: 'Calling',
      DISCONNECTED: 'Disconnected',
      FAILED: 'Failed',
      TERMINATED: 'TERMINATED',
      RESTRICTED: 'RESTRICTED',
    };
  }

  /**
   * @name CONTACT_REQUEST_STATUSES
   * @desc contact request statuses
   * @type {Object}
   */

  static get CONTACT_REQUEST_STATUSES() {
    return {
      REJECTED: 'rejected',
      BLOCKED: 'blocked',
      PENDING: 'pending',
      APPROVED: 'approved',
      INACTIVE: 'inactive',
      ACTIVE: 'active',
    };
  }

  /**
   * @name CONTACT_REQUEST_LIMIT
   * @desc contact request statuses
   * @type {Number}
   */
}

/**
 * @name initiateOutboundCall
 * @desc initiate outbound call
 * @param {String} phoneNumber
 * @return {Promise}
 */

const initiateOutboundCall = (phoneNumber) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/voice/dial/${phoneNumber}`)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name submitContactRequest
 * @desc submit contact request
 * @param {Object} contact
 * @return {Promise}
 */

const submitContactRequest = (contact) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/voice/contact`, contact)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * @name getAllContactRequests
 * @desc get all contact requests
 * @return {Promise}
 */
const getAllContactRequests = () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/voice/contacts?size=40`).then((_successLog) => {
      resolve(_successLog);
    });
  });
};

/**
 * @name endOutBoundCall
 * @desc end outbound call
 * @param {String} transactionId
 * @return {Promise}
 */
const endOutBoundCall = (transactionId, meetingId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/voice/endCall`, { transactionId, meetingId })
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getHotlineContacts = () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/voice/hotlines`)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getMeetingDetails = (meetingId) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get(`/voice/detail${meetingId ? `?meetingId=${meetingId}` : ''}`)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const bulkUpdateContactStatus = (contactIds, status) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.patch(`/voice/contact/bulkUpdate`, { contactIds, status })
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getCallHistoryUsageForLearner = () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get('/voice/callUsage')
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getCallHistoryLogsForLearner = () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get('/voice/calls')
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
const getVoiceProfile = () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get('/voice/voice-profile')
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const getAttributes = () => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.get('/location/attributes')
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const initiateVRSCall = (phoneNumber, deviceSerialNumber) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post(`/voice/dialVrsCall`, { phoneNumber, deviceSerialNumber })
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

const submitVoiceSample = (formData) => {
  return new Promise((resolve, reject) => {
    Http.REQUEST.post('/voice/voice-profile', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * End's VRS call with relevant source
 * @param {string} meetingId
 * @param {string} vrsSource
 * @return Promise
 */
const endVRSCall = (meetingId, vrsSource) => {
  const payload = {
    meetingId,
    vrsSource,
  };
  return new Promise((resolve, reject) => {
    Http.REQUEST.put(`/voice/endVrsCall`, payload)
      .then((_successLog) => {
        resolve(_successLog);
      })
      .catch((e) => reject(e));
  });
};

export {
  ContactRequestConstants,
  initiateOutboundCall,
  submitContactRequest,
  getAllContactRequests,
  endOutBoundCall,
  getHotlineContacts,
  getMeetingDetails,
  bulkUpdateContactStatus,
  getCallHistoryUsageForLearner,
  getCallHistoryLogsForLearner,
  getAttributes,
  getVoiceProfile,
  submitVoiceSample,
  initiateVRSCall,
  endVRSCall,
};
