import { useState, useEffect, useCallback } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { getMiniQuizQuestions } from '../../services/personality-quiz.service';
import { InterestProfilerService } from '../../../../../content/interest-profiler/services/interest-profiler.service';

const useInterestExplorationStepHook = (selectedLocale, answersString) => {
  const history = useHistory();
  const [questionData, setQuestionData] = useState([]);
  const [interestProfileArea, setInterestProfileArea] = useState(null);

  useEffect(() => {
    let mounted = true;

    const fetchQuestions = async () => {
      try {
        const response = await getMiniQuizQuestions();
        if (mounted) {
          setQuestionData(response || []);
        }
      } catch (err) {
        if (mounted) {
          console.error('Error fetching mini quiz questions:', err);
          setQuestionData([]);
        }
      }
    };

    const fetchInterestProfilerResults = async () => {
      if (!answersString) {
        if (mounted) {
          setInterestProfileArea(null);
        }
        return;
      }

      try {
        const service = new InterestProfilerService();
        const resultsData = await service.getInterestProfilerResults(answersString);
        if (mounted) {
          setInterestProfileArea(resultsData?.final_result?.area || null);
        }
      } catch (err) {
        if (mounted) {
          console.error('Error fetching interest profiler results:', err);
          setInterestProfileArea(null);
        }
      }
    };

    fetchInterestProfilerResults();
    fetchQuestions();

    return () => {
      mounted = false;
    };
  }, [selectedLocale, answersString]);

  const handleNext = useCallback(
    (selectedOption, description) => {
      history.push(`/personality-quiz/${selectedOption}`, { description });
    },
    [history]
  );

  const handleClick = useCallback(() => {
    if (interestProfileArea) {
      history.push(`/personality-quiz/${interestProfileArea.toLowerCase()}`);
    } else {
      history.push('/interest-profiler');
    }
  }, [interestProfileArea, history]);

  return {
    questionData,
    handleNext,
    interestProfileArea,
    handleClick,
  };
};

export default useInterestExplorationStepHook;
