import { FormattedMessage } from 'react-intl';
import { Box, Button } from '@material-ui/core';
import { Typography } from '@orijinworks/frontend-commons';
import React from 'react';
import useStyles from './no-recommendations-found.styles';
import PropTypes from 'prop-types';

const NoRecommendationsFound = ({ desc, buttonText, onClick }) => {
  const classes = useStyles({});
  return (
    <Box className={classes.container} data-testid="no-recommendations-found">
      <Typography variant="body1" className={classes.description} data-testid="no-recommendations-found-description">
        <FormattedMessage id={desc} />
      </Typography>

      <Button
        variant="contained"
        className={classes.button}
        onClick={onClick}
        aria-label={buttonText}
        data-testid="no-recommendations-found-button"
      >
        <FormattedMessage id={buttonText} />
      </Button>
    </Box>
  );
};

NoRecommendationsFound.propTypes = {
  desc: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

export default NoRecommendationsFound;
