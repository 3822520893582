export default (theme) => ({
  heading: {
    fontSize: '1.5rem',
    fontWeight: 'bold',
    paddingBottom: '24px',
  },
  subtext: {
    fontSize: '1rem',
  },
  contactRequestReached: {
    border: '2px solid yellow',
    padding: '8px',
    whiteSpace: 'pre-line',
    marginTop: '10px',
    display: 'flex',
    alignItems: 'center', // Center the icon vertically
  },
  ErrorIcon: {
    color: 'yellow', // You can adjust the icon color here
    marginRight: theme.spacing(1), // Add some spacing between the icon and text
    marginBottom: '41px',
    fontSize: '2rem',
  },
  formContainer: {
    display: 'flex',
    flexDirection: 'column',
    padding: '2px 18px',
  },
  inputField: {
    margin: theme.spacing(1),
    '& input': {
      borderRadius: '20px', // Adjust the value as needed
    },
  },

  formWrapper: {
    margin: '10px 0px',
    width: '100%',
  },
  fieldWrapper: {
    width: '100%',
  },
  submitBtn: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'left',
  },
  btnRadius: {
    borderRadius: '20px',
  },
  submitedContacts: {
    alignItems: 'center',
  },
  relationshipText: {
    marginLeft: theme.spacing(1),
  },
  contactRelationship: {
    fontFamily: 'Rubik',
    fontWeight: '500',
    fontSize: '18px',
    lineHeight: '21.33px',
    color: '#0078A2',
    letterSpacing: '1.8px',
  },
  InputLabel: {
    marginLeft: '5px',
  },
  tabCard: {
    width: '-webkit-fill-available',
  },
  icon: {
    fontSize: '1.2rem',
    marginRight: theme.spacing(1),
  },
  statusText: {
    margin: theme.spacing(0, 0.5),
    fontWeight: 'bold',
  },
  paperBox: {
    display: 'flex',
  },

  mainWrapper: {
    padding: '20px 47px',
  },
  mainTitle: {
    color: theme.palette.primary.jetBlack,
    ...theme.typography.t22,
    ...theme.typography.bold,
  },
  subText: {
    color: theme.palette.primary.jetBlack,
    ...theme.typography.t18,
    marginTop: 20,
  },

  formField: {
    width: '100%',
    marginBottom: 20,
  },
  phoneSideElement: {
    display: 'flex',
    position: 'absolute',
    top: 17,
    left: 10,
    fontSize: 16,
    marginRight: 8,
  },
  USAFlag: {
    width: 30,
    height: 20,
    marginRight: 8,
  },
  phone: {
    '& > div': {
      paddingLeft: 50,
    },
  },
  italicText: {
    fontStyle: 'italic',
  },
  parentCard: {
    margin: theme.spacing(2),
  },
  pending: {
    '&.MuiChip-root': {
      border: `1px solid ${theme.palette.yellow.main}`,
      backgroundColor: theme.palette.colors.paleYellow,
    },
  },
  pendingOutlined: {
    '&.MuiChip-outlined': {
      color: theme.palette.yellow.deep,
    },
  },
  pendingIcon: {
    color: theme.palette.yellow.main + ' !important',
  },
  blockedIcon: {
    color: theme.palette.greyScale[70] + '!important',
  },
  rejectedIcon: {
    color: theme.alert.error + '!important',
  },
  approvedIcon: {
    color: theme.palette.green.main + ' !important',
  },
  rejected: {
    '&.MuiChip-root': {
      border: `1px solid ${theme.alert.error}`,
      backgroundColor: theme.palette.primary.paleRed,
    },
  },
  rejectedOutlined: {
    '&.MuiChip-outlined': {
      color: theme.palette.red.deep,
    },
  },
  approved: {
    '&.MuiChip-root': {
      border: `1px solid`,
      backgroundColor: theme.palette.green.pale,
    },
  },
  approvedOutlined: {
    '&.MuiChip-outlined': {
      color: theme.palette.green.deep,
    },
  },
  blocked: {
    '&.MuiChip-root': {
      border: `1px solid ${theme.palette.greyScale[70]}`,
      backgroundColor: theme.palette.greyScale[30],
    },
  },
  blockedOutlined: {
    '&.MuiChip-outlined': {
      color: theme.palette.greyScale[90],
    },
  },
  inactive: {
    '&.MuiChip-root': {
      border: `1px solid ${theme.palette.greyScale[70]}`,
      backgroundColor: theme.palette.greyScale[30],
    },
    '& .MuiChip-label': {
      paddingLeft: 'inherit !important',
    },
  },
  inactiveOutlined: {
    '&.MuiChip-outlined': {
      color: theme.palette.greyScale[90],
    },
  },
  contactRow: {
    marginBottom: 10,
    borderRadius: 10,
    boxShadow: 'none',
    border: `1px solid ${theme.palette.green.pale}`,
    marginRight: 24,
  },
  iconWrapper: {
    padding: theme.spacing(2),
    marginLeft: '50px',
  },
  archiveIcon: {
    cursor: 'pointer',
  },
  archiveAvatar: {
    width: theme.spacing(4), // Set size of Avatar
    height: theme.spacing(4), // Set size of Avatar
    backgroundColor: theme.palette.common.white,
    border: `1px solid ${theme.palette.primary.main}`,
  },
});
