const styles = (theme) => ({
  customModalRoot: {
    '& .size-medium > div': {
      borderBottom: 'none',
    },
    '& .modal-footer': {
      justifyContent: 'center',
    },
  },
  modalWrapper: {
    height: 'auto',
    minWidth: '580px',
    maxWidth: '780px',
    zIndex: 9999,
  },
  title: {
    color: theme.palette.navy.main,
    textAlign: 'center',
  },
  description: {
    color: '#333333',
    textAlign: 'center',
    marginTop: '18px !important',
  },
  cancelButton: {
    color: '#E32D4E',
    border: '1px solid #E32D4E',
  },
});

export default styles;
