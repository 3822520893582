import { Box, Button, Grid, useTheme, withStyles } from '@material-ui/core';
import { Typography, ContactChip } from '@orijinworks/frontend-commons';
import React, { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import PrivateContactsCalling from '../private-contacts-calling/private-contacts-calling';
import HotlineContacts from '../hotlines-contacts/hotline-contacts';
import VoiceAuthenticationBanner from '../voice-authentication-banner/voice-authentication-banner';
import CallingModal from '../calling-modal/calling-modal';
import styles from './contacts-calling.style';
import useContactsCallingHook from './useContactsCallingHook';
import useHotlineContactsHook from '../hotlines-contacts/useHotlineContactsHook';
import { Utility, AccountCircleIcon } from '../../../../shared';
import ErrorIcon from '@material-ui/icons/Error';
import { useMeetingManager } from 'amazon-chime-sdk-component-library-react';
import StatusModal from '../status-modal/status-modal';
import { UnleashService } from '../../../../core/services/unleash/unleash';
import ArchiveContactsModal from '../archive-contact-modal/archive-contact-modal';
import { store } from '../../../../core/store';
import useUnleashHook from '../../../../core/components/unleash/useUnleashHook';
import { AddIcon } from '@orijinworks/frontend-commons';
import VRSCallingModal from '../vrs-calling-modal/vrs-calling-modal';
const ContactsCalling = (props) => {
  const {
    classes,
    handletabActive,
    TAB_VALUES,
    isClosed,
    handlePollingInterval,
    isVoiceAuthenticationEnabled,
    isVoiceIDRegistrationRequired,
    isA11yAttributeEnabled,
    profileStatus,
    isContactRequestAllowed,
  } = props;
  const meetingManager = useMeetingManager();
  const theme = useTheme();
  const { app } = store.getState();
  const [vrsContact, setVRSContact] = useState(null);

  const personAttributes = app.userDetail.personAttributes;

  const {
    approvedContacts,
    privateContacts,
    isCallingModalOpen,
    selectedContact,
    transactionId,
    meetingId,
    callStatus,
    setCallStatus,
    timePerCall,
    formatPhoneNumber,
    isCallinProgress,
    setisCallingModalOpen,
    onCallClick,
    isErrorModalOpen,
    modalStatus,
    isDailyMinutesExceeded,
    handleErroModalClose,
    handleErroModalOpen,
    maxContactQuota,
    consumedContactQuota,
    archiveContacts,
    archiveEligibleContacts,
    isCallingEnabled,
    vrsCallingModalRef,
    toggleVRSCallingModal,
    callAttributes,
    } = useContactsCallingHook(meetingManager, handlePollingInterval, isVoiceAuthenticationEnabled, isClosed);
  const renderVoiceAuthenticationBanner = () => {
    return (
      <VoiceAuthenticationBanner
        showCreateButton={true}
        handletabActive={handletabActive}
        TAB_VALUES={TAB_VALUES}
        profileStatus={profileStatus}
      />
    );
  };
  const { hotlineContacts } = useHotlineContactsHook({ isClosed });
  const hotlinesDialButtonColor = isClosed ? theme.palette.greyScale.grey90 : theme.palette.primary.main;

  const handleCallClick = (contact) => {
    if (isA11yAttributeEnabled && personAttributes['A11Y_VRS_ENABLED'] === 'true') {
      setVRSContact(contact);
      toggleVRSCallingModal();
    } else {
      onCallClick(contact);
    }
  };
  return (
    <>
      {isVoiceIDRegistrationRequired && (
        <Grid container sm={12} className={classes.voiceAuthenticationBanner}>
          <Grid item sm={12}>
            {renderVoiceAuthenticationBanner()}
          </Grid>
        </Grid>
      )}
      {approvedContacts.length === 0 && (
        <>
          <Typography id="no-contacts-title" variant="h2" className={classes.approvedContactHeader}>
            <FormattedMessage id="no-contacts-heading" />
          </Typography>
          <Box className={classes.approvedContactContainer}>
            <Typography id="non-contacts-description" variant="body1" className={classes.approvedContactSubtext}>
              <FormattedMessage
                id={isContactRequestAllowed ? 'no-contacts-description' : 'no-contacts-description-simple'}
              />
            </Typography>
            {isContactRequestAllowed && (
              <Button
                variant="contained"
                id="addContactButton"
                className={classes.addContactButton}
                onClick={(event) => handletabActive(event, TAB_VALUES.Connections)}
              >
                <AddIcon size={24} color="#FFFFFF" />
                <Typography variant="body" className={classes.addContactButtonLabel}>
                  <FormattedMessage id="addAContact" />
                </Typography>
              </Button>
            )}
          </Box>
        </>
      )}
      {approvedContacts.length > 0 && (
        <>
          <Typography variant="h2" className={classes.approvedContactHeader}>
            <FormattedMessage id="no-contacts-heading" />
          </Typography>
          <Typography id="approved-contacts" variant="body1" className={classes.contactsMonitoringText}>
            <FormattedMessage id="contacts-description" />
          </Typography>
          <Box height={24} />
          {approvedContacts.map((contact, index) => (
            <ContactChip
              key={index}
              icon={<AccountCircleIcon />}
              name={Utility.getFullName(contact.firstName, contact.lastName)}
              relationship={
                <FormattedMessage id={contact.relationshipType.toLowerCase()}>
                  {(text) => text.toUpperCase()}
                </FormattedMessage>
              }
              phoneNumber={formatPhoneNumber(contact.phoneNumber)}
              isCallingEnabled={isCallingEnabled && !isClosed} // Combine both checks here
              onCallClick={() => handleCallClick(contact)}
            />
          ))}
        </>
      )}

      {hotlineContacts?.length > 0 && (
        <HotlineContacts
          hotlineContacts={hotlineContacts}
          isClosed={isClosed}
          dialButtonColor={hotlinesDialButtonColor}
          handlePollingInterval={handlePollingInterval}
          handleCallClick={handleCallClick}
        />
      )}
      {privateContacts?.length > 0 && (
        <PrivateContactsCalling isClosed={isClosed} privateContacts={privateContacts} handleCallClick={handleCallClick} />
      )}

      <Box className={classes.footerContainer}>
        <Box className={classes.ErrorIconContainer}>
          <ErrorIcon className={classes.ErrorIcon} />
        </Box>
        <Box className={classes.ErrorTextContainer}>
          <Typography variant="body1" className={classes.ErrorText}>
            <FormattedMessage id="contacts-disclaimer" />
          </Typography>
        </Box>
      </Box>

      <CallingModal
        isCallingModalOpen={isCallingModalOpen}
        setisCallingModalOpen={setisCallingModalOpen}
        setonClose={() => {}}
        selectedContact={selectedContact}
        transactionId={transactionId}
        meetingId={meetingId}
        callStatus={callStatus}
        setCallStatus={setCallStatus}
        timePerCall={timePerCall}
        isCallinProgress={isCallinProgress}
        callAttributes={callAttributes}
      />

      <VRSCallingModal
        modalRef={vrsCallingModalRef}
        isOpen={false}
        contact={vrsContact}
        closeModal={toggleVRSCallingModal}
        onContinue={() => {}}
        onCallClick={onCallClick}
      />

      <StatusModal
        status={modalStatus}
        description={isDailyMinutesExceeded ? <FormattedMessage id="dailyMinutesLimitExceededError" /> : ''}
        handleStatusModalOpen={handleErroModalOpen}
        handleStatusModalClose={handleErroModalClose}
        isStatusModalOpen={isErrorModalOpen}
      />
      {archiveEligibleContacts.length > 0 && (
        <ArchiveContactsModal
          open={true}
          onClose={() => {}}
          onArchive={archiveContacts}
          maxContactQuota={maxContactQuota}
          consumedContactQuota={consumedContactQuota}
          contacts={archiveEligibleContacts}
        />
      )}
    </>
  );
};
export default withStyles(styles)(ContactsCalling);
