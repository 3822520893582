import React from 'react';
import { Box, Button, withStyles } from '@material-ui/core';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import { FormattedMessage } from 'react-intl';
import { Constant } from '../../../../../shared';
import styles from './feature-navigation.styles';
import { useHistory } from 'react-router-dom';

const FeatureNavigation = ({ classes }) => {
  const history = useHistory();

  const goBack = () => {
    history.goBack();
  };

  const startOver = () => {
    history.push('/personality-quiz');
  };

  return (
    <Box className={classes.root} data-testid="feature-navigation">
      <Box className={classes.container}>
        <img
          src={`${Constant.CDN_BASE_URL}/assets/img/program-exploration-wizard-logo.png`}
          alt="Quiz Logo"
          className={classes.logo}
          data-testid="feature-navigation-logo"
        />
        <Box className={classes.buttonContainer}>
          <Button
            variant="contained"
            color="primary"
            className={classes.backButton}
            data-testid="back-button"
            onClick={goBack}
            aria-label="Go back to previous page"
          >
            <FormattedMessage id="back" />
          </Button>
          <Button
            variant="outlined"
            color="primary"
            className={classes.startOverButton}
            data-testid="start-over-button"
            onClick={startOver}
            startIcon={<RestartAltIcon />}
            aria-label="Start the quiz over from beginning"
          >
            <FormattedMessage id="startOver" />
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(FeatureNavigation);
