import { Http } from '../../../../core';
import { getScore, getLastAcessedDate } from './utils';
import { Constant, Utility } from './../../../../shared/services';

export class TranscriptService {
  _utilityService = new Utility();
  /**
   * @name fetchUserTranscript
   * @desc Fetches achievements-transcript related data from server
   * @return {Promise}
   */
  fetchUserTranscript = () => {
    return new Promise((resolve, reject) => {
      Http.REQUEST.get('/achievement/transcript?noLoader=true')
        .then((_successLog) => {
          const { data } = _successLog;

          let categoriesHours = {
            [Constant.CONTENT_AREAS_ENUM.Education]: 0,
            [Constant.CONTENT_AREAS_ENUM.Rehab]: 0,
            [Constant.CONTENT_AREAS_ENUM.Workforce]: 0,
            [Constant.CONTENT_AREAS_ENUM.ReEntry]: 0,
            [Constant.CONTENT_AREAS_ENUM.Enrichment]: 0,
          };
          let totalHours = 0;

          const issueDate = this._utilityService.getCurrentDate();
          if (!data) {
            resolve({
              docId: '',
              issueDate,
              transcriptListDtos: [],
              totalHours,
              categoriesHours,
            });
            return;
          }

          data.transcriptListDtos.forEach((item) => {
            let bundleHours = 0;
            item.children.forEach((child) => {
              const childHours = parseFloat(child.hours.toFixed(1));
              child.score = getScore(child);
              child.lastDate = getLastAcessedDate(child);
              bundleHours += childHours;
              categoriesHours[child.contentAreaId] += childHours;
            });
            item.hours = bundleHours;
            totalHours += parseFloat(item.hours.toFixed(1));
          });

          const dataWithCalc = {
            ...data,
            totalHours,
            categoriesHours,
            issueDate,
          };
          resolve(dataWithCalc);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };
}
