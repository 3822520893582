import { AUTH_CONSTANTS } from '../../auth/constants/auth-constants';
import { getCachedDeviceGroup } from '../../auth/utils';
import { AuthGuardsHttp } from './auth-guards-http/auth-guards-http';
import { getUserDAT } from './user-device-pairing/user-device-pairing.service';

/**
 * Function to transform the AuthGuards list.
 *
 * @param {array} authGuardsList - The list of AuthGuards.
 * @returns {array} The transformed list of AuthGuards.
 */
const transformAuthGuardsList = (authGuardsList) => {
  return authGuardsList.map((authGuard) => {
    return {
      guard: authGuard.guard.toUpperCase(),
      action: authGuard.action?.toUpperCase(),
      payload: authGuard.payload,
    };
  });
};

/**
 * Function to fetch the AuthGuards list.
 *
 * @param {string} username - The username of the user.
 * @returns {Promise} The promise object of the AuthGuards list.
 */
export const fetchAuthGuardsList = (username) => {
  return new Promise((resolve, reject) => {
    const userDAT = getUserDAT(username);
    const cachedData = getCachedDeviceGroup();
    const deviceGroup = cachedData?.deviceGroup?.name;
    AuthGuardsHttp.REQUEST.post('/auth/guard/list', { deviceAccessToken: userDAT, deviceGroup })
      .then((response) => {
        if (response?.data?.length > 0) {
          resolve(transformAuthGuardsList(response.data));
        }
        resolve([]);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to persist the Terms of Service action.
 *
 * @param {object} payload - The payload to persist the Terms of Service action.
 * @returns {Promise} The promise object of the Terms of Service action.
 */
export const persistTermsAndService = (payload) => {
  return new Promise((resolve, reject) => {
    AuthGuardsHttp.REQUEST.put(`/auth/guard/${AUTH_CONSTANTS.AUTH_GUARDS_LIST.TERMS_OF_SERVICE}/action`, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

/**
 * Function to validate the face. This function will be used for both face registration and face validation.
 *
 * @param {object} payload - The payload to validate the face.
 * @param {string} action - The action to perform. Either 'authenticate' or 'register'.
 * @returns {Promise} The promise object of the face validation.
 */
export const validateFace = (payload, action) => {
  return new Promise((resolve, reject) => {
    const actionPath = action === AUTH_CONSTANTS.AUTH_GUARDS_ACTION.AUTHENTICATE ? 'authenticate' : 'validate';
    AuthGuardsHttp.REQUEST.post(
      `/auth/guard/${AUTH_CONSTANTS.AUTH_GUARDS_LIST.BIOMETRIC_AUTHENTICATION}/${actionPath}`,
      payload
    )
      .then((response) => {
        const data = response?.data || {};
        if (data.status === AUTH_CONSTANTS.FACE_VALIDATION_STATUS.SUCCESS) {
          resolve();
        } else if (data.status === AUTH_CONSTANTS.FACE_VALIDATION_STATUS.FAILED) {
          const errorName = data.payload?.toUpperCase();
          const errorMessage =
            AUTH_CONSTANTS.FACE_VALIDATION_ERROR_MESSAGES[errorName] ||
            AUTH_CONSTANTS.FACE_VALIDATION_ERROR_MESSAGES.SOMETHING_WENT_WRONG;
          reject(errorMessage);
        } else {
          reject(AUTH_CONSTANTS.FACE_VALIDATION_ERROR_MESSAGES.SOMETHING_WENT_WRONG);
        }
      })
      .catch((error) => {
        reject(AUTH_CONSTANTS.FACE_VALIDATION_ERROR_MESSAGES.SOMETHING_WENT_WRONG);
      });
  });
};
